import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'

//images
import warning from "../../images/warning.svg"
import info from "../../images/info.svg"

function Alert({type, title, message}){
    return(
        <AlertContainer className={type === 'warning' ? css(Warning) : css(Default)}>
            <img src={type === 'warning' ? warning : info}/>
            <div>
                <AlertTitle>{title}</AlertTitle>
                <AlertMessage>{message}</AlertMessage>
            </div>
        </AlertContainer>
    )
}

export default Alert

const AlertContainer = styled.div`
    border-radius: 8px;
    height:109px;
    padding:16px;
    display:flex;
    align-items:flex-start;
    gap:12px;
    margin-top:24px;
`

const Default = css`
    border-left: 8px solid var(--color-stroke-container-info);
    background: #EDF1F6;
`

const Warning = css`
    border-left: 8px solid var(--color-stroke-container-warning);
    background: #FAF1ED;
`

const AlertTitle = styled.b`
    margin: 0;
    line-height:24px;
`

const AlertMessage = styled.p`
    margin: 0;
    line-height:24px;
`