import styled from '@emotion/styled'
import { signout } from '../../util/signout';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUserData } from '../../redux/profileSlice';

//images
import logo from "../../images/logo.svg"
import signoutIcon from '../../images/sign-out.svg';

function Header({auth}){

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userProfile = useSelector(state => state.userProfile)

    const handleSignout = () => {
        signout(userProfile.latestSessionDetail)
        .then(result => {
            if(result.ok){
                localStorage.removeItem("profile");
                window.pendo.clearSession()
                dispatch(setUserData({}))
                navigate(process.env.PUBLIC_URL + "/signin")
            }
        })
        .catch(err => console.log(err))
    }

    return(
        <HeaderContainer>
            <img src={logo}/>
            {
                auth != false ? 
                <HeaderActions>
                    <User>
                        <span>
                            {userProfile.firstName ? userProfile.firstName.substring(0, 1): ''}
                            {userProfile.lastName ? userProfile.lastName.substring(0, 1) : ''}
                        </span>
                    </User>
                    <Signout onClick={handleSignout}>
                        <img src={signoutIcon}/>
                    </Signout>
                </HeaderActions>
                : null
            }
            
        </HeaderContainer>
    )
}

export default Header

const HeaderContainer = styled.header`
    display:flex;
    align-items:center;
    justify-content:space-between;
    &>div{
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
`

const HeaderActions = styled.div`
    display:flex;
    align-items:center;
    gap:8px;
`

const User = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    background: var(--color-fill-container-brand);
    border-radius: 80px;
    width:32px;
    height:32px;
    span{
        color: var(--color-text-interactive-brand-default);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
`

const Signout = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
`