import {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { setUserData } from '../redux/profileSlice';
import { getUserAttempts } from '../util/getUserAttempts';
import { setUserAttempts } from '../redux/userAttemptsSlice';
import Tooltips from '../components/shared/Tooltips';
import SessionCheck from '../util/SessionCheck';
import mixpanel from "mixpanel-browser";
import store from "../redux/store";
import parse from "html-react-parser";

function PageWrapper(props){

    const dispatch = useDispatch()

    const handleGetProfile = async () => {
        const storedProfile = JSON.parse(localStorage.getItem('profile'));
        console.log('18', storedProfile);
        if (storedProfile) {
            mixpanel.identify(storedProfile?.id)
            mixpanel.people.set({
                '$id': storedProfile.id,
                '$name': storedProfile?.firstName + ' ' + storedProfile?.lastName,
                '$email': storedProfile?.username,
                '$sessionId': storedProfile?.latestSessionDetail,
            });
            dispatch(setUserData(storedProfile))
            let pendoId = storedProfile.pendoId
            window.dispatchEvent(new CustomEvent("getPendoId", { detail: pendoId }));
        }
    }

    useEffect(()=>{
        handleGetProfile()
        getUserAttempts()
        .then(response=>response.json())
        .then(data=>{
            dispatch(setUserAttempts(data))
        })
    },[])
    

    return(
        <>
            <SessionCheck />
            <Tooltips/>
            {props.children}
        </>
    )
}

export default PageWrapper