import SERVER_URL from './SERVER_URL'

export const signin = async (formData) => {
    return fetch(`${SERVER_URL}/signin`, {
        method: "post",
        credentials : "include",
        headers:{
            "Content-Type": "application/json"
        },
        body:JSON.stringify(formData)
    })
}