import { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import { Page, AuthPage, PrimaryButton, InputGroup, Label, InputContainer } from '../global_styles/global_styles';
import { useNavigate } from 'react-router-dom';
import { signin } from '../util/signin';
import Alert from "./shared/Alert"
import Header from "./shared/Header"
import { useSelector } from 'react-redux';

function Signin() {

    const navigate = useNavigate()

    const userProfile = useSelector(state => state.userProfile)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [signinDisabled, setSigninDisabled] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleSubmit = async (e) => {
      e.preventDefault()
      let formData = {
          email: email,
          password: password
      }
      signin(formData)
      .then(result => result.json())
      .then(data => {
          if(data && data.username){
            localStorage.setItem('profile', JSON.stringify(data));
            if(data.acceptedDataPolicy === false){
              navigate(process.env.PUBLIC_URL + "/terms")
            }else{
              navigate(process.env.PUBLIC_URL + "/dashboard")
            }
          }
          else{
            // console.log(data)
            setAlertMessage({
                title: 'Log in unsuccessful',
                message: data
            })
            setShowAlert(true)
            setTimeout(()=>{
              setShowAlert(false)
            },8000)
          }
      })
      .catch(err => console.log(err))
    }

    useEffect(()=>{
      if(email && password){
        setSigninDisabled(false)
      }
    },[email, password])

    return (
      <Page className={css(AuthPage)}>
        <Header auth={false}/>
        <SigninH2>PTE AI Practice</SigninH2>
        <SigninSubtitle>Practice PTE Academic questions and get feedback on your performance</SigninSubtitle>
        <SigninH3>Log in</SigninH3>
        <Form 
          onSubmit={handleSubmit}
          id='signinform'
        >
            <InputGroup>
                <Label>Username</Label>
                <InputContainer>
                  <input
                      type="email"
                      placeholder="e.g. John-smith@mail.com"
                      onChange={(e)=>{setEmail(e.target.value)}}
                      required
                  />
                </InputContainer>
            </InputGroup>
            <InputGroup>
                <Label>Password</Label>
                <InputContainer>
                  <input
                      type="password"
                      // placeholder="password"
                      onChange={(e)=>{setPassword(e.target.value)}}
                      required
                  />
                </InputContainer>
            </InputGroup>
            {
              showAlert ? 
              <Alert type="warning" title={alertMessage.title} message={alertMessage.message}/>
              : null
            }
        </Form>
        <SignInActions>
          <p>By logging in you agree to Pearson's <a href="https://www.pearsonpte.com/policy-center/privacy-policy" target="_blank">Privacy Policy</a></p>
          <button 
            type="submit"
            form="signinform"
            className={cx(PrimaryButton)}
            disabled={signinDisabled}
            >
                  Log in
          </button>
        </SignInActions>
        
      </Page>
    );
  }
  
  export default Signin;

  const SigninH2 = styled.h2`
    margin:0;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-top:40px;
  `

  const SigninSubtitle = styled.p`
    margin:0;
  `

  const Form = styled.form`
    width:100%;
  `

  const SigninH3 = styled.h3`
    margin-top:40px;
    margin-bottom:40px;
  `

  const SignInActions = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    padding: 24px 16px;
    width:100%;
    @media(max-height: 650px){
        position:relative;
        width: calc(100% + 32px);
        margin-left: -16px; 
    }
    p{
      font-weight: 400;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #000;
      text-align:center;
      width:100%;
    }
    a{
      color: #000;
      font-weight: 600;
      text-decoration-line: underline;
    }
  `