import {useState, useEffect, useRef} from 'react'
import { cx, css } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import {PrimaryButton, TaskContainer, ShortInstructions} from "../global_styles/global_styles"
import {getTypeProperName} from "../util/getTypeProperName.js"
import { useSelector, useDispatch } from 'react-redux';
import { saveAudioRecording } from '../util/saveAudioRecording.js'
import { saveAttempt } from '../util/saveAttempt.js'
import { getUserAttempts } from '../util/getUserAttempts.js'
import { setUserAttempts } from '../redux/userAttemptsSlice.js'
import { getScore } from '../util/getScore.js'
import parse from "html-react-parser";
import Timer from './shared/Timer.jsx'
import { useNavigate } from 'react-router-dom';
import Alert from './shared/Alert.jsx'
import ImageModal from './shared/ImageModal.jsx'
import TextTimer from "./shared/TextTimer.jsx"
import { useWebSocket } from '../util/WebSocketProvider'
import { setWebSocketState } from '../redux/webSocketSlice.js'
import { startAttempt } from '../util/startAttempt.js'
import { savePAFFeedback } from '../util/savePAFFeedback.js'
import { getContentFeedback } from '../util/getContentFeedback.js'
import { saveContentFeedback } from '../util/saveContentFeedback.js'

//images
import micGrey from "../images/microphone_grey.svg"
import micDark from "../images/microphone_dark.svg"
import micWhite from "../images/microphone_white.svg"
import clock from "../images/clock.svg"
import record from "../images/record.svg"
import recordSuccess from "../images/record_success.svg"
import recordError from "../images/record_error.svg"
import recordUnavailable from "../images/record_unavailable.svg"
import mixpanel from "mixpanel-browser";


const pulse = keyframes`
    0% {
        transform: scale(0.1);
        background: rgba(21, 73, 139, 0.5);
    }

    70% {
        transform: scale(1);
        background: rgba(21, 73, 139, 0.08);
    }
    100% {
        transform: scale(1);
        background: rgba(21, 73, 139, 0);
    }
`


function Task({questionData, questionId, typeData, handleShowModal}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const webSocket = useWebSocket();

    const mimeType = 'audio/wav';
    const SAMPLE_RATE = 16000;

    const userProfile = useSelector(state => state.userProfile)
    const userAttempts = useSelector(state => state.userAttempts)

    const [permission, setPermission] = useState(null);
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState({title: '', message: ''})
    const [stream, setStream] = useState(null);
    const [shortInstructions, setShortInstructions] = useState(``);
    const [startTaskDisabled, setStartTaskDisabled] = useState(true)
    const [finishTaskDisabled, setFinishTaskDisabled] = useState(true)
    const [timerValue, setTimerValue] = useState(typeData.prepTime ? typeData.prepTime : typeData.recordTime)
    const [resetTimer, setResetTimer] = useState(false)
    const [recordingStatus, setRecordingStatus] = useState("inactive");
    const [audioChunks, setAudioChunks] = useState([]);
    const [audio, setAudio] = useState(false);
    const [totalAttempts, setTotalAttempts] = useState(0)
    const [showImageModal, setShowImageModal] = useState(false)
    const [audioRecorder, setAudioRecorder] = useState();
    const [attemptId, setAttemptId] = useState(null)
    const [feedbackId, setFeedbackId] = useState(null)

    const mediaRecorder = useRef(null);
    const audioContextRef = useRef(null);

    const getShortInstructions = () => {
        let html = ``
        if(recordingStatus === 'inactive'){
            html = `<div className=${css(ShortInstructions)}>
                        <div>
                            <img src="${clock}"/>
                            ${typeData.shortInstructions.prep.main}
                        </div>
                        ${typeData.shortInstructions.prep.sub ? typeData.shortInstructions.prep.sub : ''}
                    </div>`
        }else if(recordingStatus === "recording"){

            html = `<div className=${css(ShortInstructions)}>
                        <div>
                            <img src="${clock}"/>
                            ${typeData.shortInstructions.record.main}
                        </div>
                        ${typeData.shortInstructions.record.sub ? typeData.shortInstructions.record.sub : ''}
                    </div>`
        }
        setShortInstructions(html)
    }

    const getMicrophonePermission = async () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then((mediaStream) => {
          setPermission(true);
          setStream(mediaStream);
        })
        .catch((error) => {
            setAlertMessage({
                title: 'Is your microphone working?',
                message: `Try checking the audio settings on your browser`
            })
            setStartTaskDisabled(true)
            setShowAlert(true)
            setPermission(false);
        });
    };

    const processAudio = (event) => {
        const inputBuffer = event.inputBuffer.getChannelData(0);

        const PCM16iSamples = new Int16Array(inputBuffer.length);

        for (let i = 0; i < inputBuffer.length; i++) {
            let val = Math.floor(32767 * inputBuffer[i]);
            val = Math.min(32767, val);
            val = Math.max(-32768, val);

            PCM16iSamples[i] = val;
        }
        webSocket?.send(PCM16iSamples, { binary: true});
    }


    const startRealTimeSpeechRecognition = async () => {
        const bufferSize = 4096;
        let audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
        let audioInput = audioContext.createMediaStreamSource(stream);

        const audioRecorder = audioContext.createScriptProcessor(
            bufferSize,
            1,
            1
        );
        audioRecorder.onaudioprocess = processAudio;
        setAudioRecorder(audioRecorder);

        audioInput
            .connect(audioRecorder)
            .connect(audioContext.destination);

        audioContextRef.current = audioContext
    }

   
    const handleStartRecording = async () => {
        if(!permission){
            getMicrophonePermission()
        }else{
            startRecording()
        }
    }

    const startRecording = async () => {
        // console.log('start recording')
        //handleTimer
        setTimerValue(typeData.recordTime)
        setResetTimer(true)
        //start recording status
        setRecordingStatus("recording");
        //start an attempt and feedback record in the database
        startAttempt()
        .then(response=>response.json())
        .then(data=>{
            setAttemptId(data.attemptId)
            setFeedbackId(data.feedbackId)
        })
        if(typeData.type === 'describe_image' || typeData.type === 'retell_lecture'){
            //start streaming via websocket
            webSocket?.send(JSON.stringify({startStreaming: true}), { binary: false});
            startRealTimeSpeechRecognition()
        }
        //start recording media to save in the database, seperate from streaming to ORA
        if(!mediaRecorder.current){
            const media = new MediaRecorder(stream, { type: mimeType });
            // console.log(media)
            mediaRecorder.current = media;
            mediaRecorder.current.start();
            let localAudioChunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
                localAudioChunks.push(event.data);
            };
            setAudioChunks(localAudioChunks);
        }
    };

    const handleStopRecording = async () => {
        //stop recording status
        setRecordingStatus("inactive");
        //signal end of stream to websocket
        //and send mongo ids for saving attempt and feedback 
        webSocket?.send(JSON.stringify({ endOfStream: true, attemptId: attemptId, feedbackId: feedbackId, questionId: questionData.id }), { binary: false });
        //stop streaming audio
        if(audioContextRef.current){
            await audioContextRef.current.close();
        }
        audioRecorder?.disconnect();

        //stops recording and start saving audio file and attempt data to the database, seperate from streaming to ORA
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
           const audioBlob = new Blob(audioChunks, { type: mimeType });
           setAudio(true) 
           const formData = new FormData();
           formData.append('audio', audioBlob, `${questionData.id}_${questionData.type}_${questionData.topic.toLowerCase().replaceAll(' ', '_')}/${userProfile.id}_${totalAttempts + 1}.wav`);
           let savedFile;
           saveAudioRecording(formData)
           .then(response=>response.json())
           .then(data=>{
                savedFile = data.file
                let attemptData = {
                    attemptId: attemptId,
                    feedbackId: feedbackId,
                    questionId: questionData.id, 
                    audioUrl: savedFile
               }
               saveAttempt(attemptData)
               .then(response=>response.json())
               .then(data=>{
                    // if(typeData.type === 'read_aloud' || typeData.type === 'repeat_sentence' || typeData.type === 'short_question'){
                    if(typeData.type === 'read_aloud' || typeData.type === 'repeat_sentence'){
                        const nonStreamingFormData = new FormData();
                        nonStreamingFormData.append('audio', audioBlob, `${questionData.id}_${questionData.type}_${questionData.topic.toLowerCase().replaceAll(' ', '_')}/${userProfile.id}_${totalAttempts + 1}.wav`);
                        nonStreamingFormData.append('attemptId', data.attemptId)
                        nonStreamingFormData.append('feedbackId', data.feedbackId) 
                        nonStreamingFormData.append('referenceText', questionData.question.text) 

                        //get score via nonstreaming endpoint if question type is closed-ended
                        getScore(nonStreamingFormData)
                         .then(response=>response.json())
                         .then(pafEvaluation=>{
                            let PAFdata = {
                                attemptId: attemptId,
                                feedbackId: feedbackId,
                                pafEvaluation: pafEvaluation
                            }
                            savePAFFeedback(PAFdata)
                            //get the transcript from the evaluation
                            let transcript = pafEvaluation.detailed.displayText
                            let data = {
                                scoringCategory: typeData.scoringCategory,
                                text: transcript,
                                model_sequence: questionData.question.text
                            }
                            getContentFeedback(data)
                            .then(response=>response.json())
                            .then(data=>{
                                let contentData = {
                                    feedbackId: feedbackId,
                                    contentEvaluation: data
                                  }
                                  //save content feedback in mongo database
                                  saveContentFeedback(contentData)
                            })
                         })
                    }
                    

                    getUserAttempts()
                    .then(response=>response.json())
                    .then(userAttempts=>{
                        dispatch(setUserAttempts(userAttempts))
                    })
               })
           })

           setAudioChunks([]);
        };
        mediaRecorder.current = null;
        // Stop all tracks on the media stream to release the microphone
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setStream(null)
        // console.log(navigator.mediaDevices.enumerateDevices())
    };

    const resetTimerCallback = () => {
        setResetTimer(false)
    }

    const handleTimerEnd = () => {
        if(!audio && recordingStatus === "inactive"){
            handleStartRecording()
        }else{
            handleStopRecording()
        }
    }

    const toggleImageModal = () => {
        setShowImageModal(!showImageModal)
        handleShowModal()
    }

    const handleViewScore = () => {
        mixpanel.track('View Score - Task', { 'questionId': questionId })
        navigate(navigate(process.env.PUBLIC_URL + `/question/${questionId}/score`))
    }

    useEffect(()=>{
        if(
            typeData.type === 'describe_image' || 
            typeData.type === 'retell_lecture'
        ){
            dispatch(setWebSocketState('open'))
        }
        else{
            dispatch(setWebSocketState('closed'))
        }
        return () => {dispatch(setWebSocketState('closed'))}
    },[typeData])

    useEffect(()=>{
        if(
            typeData.type === 'repeat_sentence' || 
            typeData.type === 'short_question'
        ){
            // console.log('test effect')
            handleStartRecording()
        }
    },[typeData])

    // This useEffect will trigger startRecording when both conditions are met
    useEffect(() => {
        if (permission && stream) {
            // console.log('test effect')
            startRecording(); // Start recording only when stream and permission are ready
        }
    }, [permission, stream]);

    //enable start recording button after 3 seconds
    useEffect(()=>{
        setTimeout(()=>{
            setStartTaskDisabled(false)
        },3000)
    },[])

    //enable finish recording button after 3 seconds
    useEffect(()=>{
        if(!audio && recordingStatus === "recording"){
            setTimeout(()=>{
                setFinishTaskDisabled(false)
            },3000)
        }
    },[audio, recordingStatus])


    useEffect(()=>{
        if(userAttempts){
            let questionMatch = userAttempts.filter(question => question.questionId === questionData.id) 
            if(questionMatch[0]){
                setTotalAttempts(questionMatch[0].totalAttempts)
            }
        }
    },[userAttempts])

    useEffect(()=>{
        if(typeData.shortInstructions){
            getShortInstructions()
        }
    },[recordingStatus])

    return(
        <>
            {
                showImageModal ? 
                <ImageModal 
                    image={questionData.question.image}
                    toggleImageModal={toggleImageModal}
                />
                : null
            }
            {
                questionData ? <>
                    <h2>{getTypeProperName(questionData.type)}</h2>
                    <TaskContainer>
                    {!audio ? 
                        <>
                            {
                                shortInstructions != '' ?
                                <>{parse(shortInstructions)}</>
                                : null
                            }
                            {
                                (questionData.type === 'retell_lecture' && recordingStatus === 'recording') || 
                                (questionData.type === 'repeat_sentence' && recordingStatus === 'recording') || 
                                (questionData.type === 'short_question' && recordingStatus === 'recording') || 
                                (questionData.type != 'retell_lecture' && questionData.type != 'repeat_sentence' && questionData.type != 'short_question') ?
                                <Timer 
                                    value={timerValue} 
                                    handleTimerEnd={handleTimerEnd}
                                    resetTimer={resetTimer}
                                    resetTimerCallback={resetTimerCallback}
                                />
                                : null
                            }
                            {
                                (questionData.type === 'retell_lecture' && recordingStatus === 'inactive') ?
                                <TextTimer 
                                    value={timerValue}
                                    handleTimerEnd={handleTimerEnd}
                                    startTimer={true} 
                                />
                                : null
                            }
                            {
                                questionData.type === 'read_aloud' ?
                                <QuestionText>
                                    {questionData.question.text}
                                </QuestionText>
                                : null
                            }
                            {
                                questionData.type === 'repeat_sentence' ?
                                <QuestionRepeat>
                                    <img src={micDark}/>
                                    <p>Repeat the exact sentence</p>
                                </QuestionRepeat>
                                : null
                            }
                            {
                                questionData.type === 'describe_image' ?
                                <QuestionImage 
                                    onClick={toggleImageModal}
                                >
                                    <div>
                                        <img src={questionData.question.image}/>
                                    </div>
                                    <p>Tap/Click the image to zoom</p>
                                </QuestionImage>
                                : null
                            }
                            {
                                questionData.type === 'short_question' ?
                                // <p>Your time to answer</p>
                                <QuestionRepeat>
                                    <img src={micDark}/>
                                    <p>Your time to answer</p>
                                </QuestionRepeat>
                                : null
                            }
                            {
                                recordingStatus === 'recording' ? 
                                <RecordingIndicator type={questionData.type}>
                                    <div className={RecordingAnimation}>
                                        <img src={record}/>
                                    </div>
                                    <p>Recording...</p>
                                </RecordingIndicator>
                                : null
                            }
                            {
                                showAlert ? 
                                <>
                                <RecordingIndicator>
                                    <img src={recordError}/>
                                </RecordingIndicator>
                                <Alert type="warning" title={alertMessage.title} message={alertMessage.message}/>
                                </>
                                : null
                            }
                            
                        </>
                        : 
                        <SuccessMesssage>
                            <img src={recordSuccess}/>
                            <p>Your answer was recorded</p>
                        </SuccessMesssage>
                    }
                    </TaskContainer>
                    {
                        !audio && recordingStatus === "inactive" && typeData.type !== 'repeat_sentence' && typeData.type !== 'short_question' ? 
                        <button
                            className={css(PrimaryButton)}
                            onClick={handleStartRecording}
                            disabled={startTaskDisabled}
                        >
                            <img src={startTaskDisabled ? micGrey : micWhite}/>
                            Start recording now
                        </button>
                        : null
                    }
                    {
                        !audio && recordingStatus === "recording" ?
                        <button
                            className={css(PrimaryButton)}
                            onClick={handleStopRecording}
                            disabled={finishTaskDisabled}
                        >
                            Finish
                        </button>
                        : null
                    }
                    {
                        audio && recordingStatus === "inactive" ?
                        <button
                            className={css(PrimaryButton)}
                            onClick={handleViewScore}

                        >
                            View score
                        </button>
                        : null
                    }
                </>
                : null
            }
            
        </>
    )
}

export default Task

const QuestionText = styled.p`
    border-radius: 10px;
    background: var(--primary-colours-vet-blue-1-f-0-f-6-ff);
    padding:16px;
    font-size: var(--font-size-b1);
    line-height: var(--font-line-height-b1);
`

const QuestionImage = styled.div`
    width:100%;
    div{
        width:100%;
        cursor:pointer;
        img{
            width:100%;
            object-fit:contain;
        }
    }
    p{
        text-align:center;
        margin:0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--color-text-tertiary);
    }
`

const QuestionRepeat = styled.div`
    display:flex;
    align-items:center;
    gap:8px;
    justify-content:center;
    margin-top: 30%;
    margin-bottom:40px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-text-primary);
`

const RecordingIndicator = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:${props=> props.type === 'retell_lecture' ? '100%' : 'unset'};
    img{

    }
    p{
        color: var(--color-text-tertiary);
        font-size: 16px;
        font-weight: 600;
        line-height:24px;
        text-align:center;
    }
`

const RecordingAnimation = css`
    padding: 1rem;
    border-radius: 50%;
    position: relative;
    max-width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        position: relative;
        z-index: 100;
    }
    
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        z-index: 10;
        animation: ${pulse} 4s ease-out infinite;
    }
    
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        z-index: 20;
        animation: ${pulse} 4s ease-out infinite;
        animation-delay: 2s;
    }
`

const SuccessMesssage = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:100%;
    p{
        color: var(--color-text-success);
        font-size: 16px;
        font-weight: 600;
        line-height: 150%; 
        text-align:center;
    }
`

