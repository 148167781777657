import SERVER_URL from './SERVER_URL'

export const saveAudioRecording = async (formData) => {
    return fetch(`${SERVER_URL}/upload-audio`, {
        method: "post",
        credentials : "include",
        headers:{

        },
        body: formData
    })
}