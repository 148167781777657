import {configureStore} from '@reduxjs/toolkit';
import profileReducer from './profileSlice';
import userAttemptsReducer from './userAttemptsSlice';
import webSocketReducer from './webSocketSlice';
import feedbackReducer from './feedbackSlice';

const store = configureStore({
    reducer: {
        userProfile : profileReducer,
        userAttempts: userAttemptsReducer,
        webSocketState: webSocketReducer,
        processedFeedback: feedbackReducer
    }
});
export default store;
