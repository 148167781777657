import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: "userProfile",
    initialState: {},
    reducers : {
        setUserData : (state, action) => {
            state = action.payload
            return state
          }
    }
});
  
export const {setUserData } = profileSlice.actions;

export default profileSlice.reducer;