import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SERVER_URL from './SERVER_URL'
import { signout } from '../util/signout';
import { useDispatch } from 'react-redux';
import { setUserData } from '../redux/profileSlice';

const SessionCheck = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleSignout = () => {
        if(localStorage.getItem('profile')){
          const storedProfile = JSON.parse(localStorage.getItem('profile'))
          signout(storedProfile.latestSessionDetail)
          .then(result => {
              if(result.ok){
                  localStorage.removeItem("profile");
                  if(window.pendo){
                      window.pendo.clearSession()
                  }
                  dispatch(setUserData({}))
                  navigate(process.env.PUBLIC_URL + "/signin")
              }
          })
          .catch(err => console.log(err))
        }
    }


  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        fetch(`${SERVER_URL}/session-status`)
        .then(response=>response.json())
        .then(data=>{
            // console.log(data)
            if (!data.loggedIn) {
                handleSignout()
            }
        })
      } catch (error) {
        // Handle error if fetch fails (e.g., server down)
        console.error('Session check failed', error);
      }
    }, 5 * 60 * 1000); // Check every 5 minutes
    // }, 1000 * 60); //check every 1 minute for testing purposes

    return () => clearInterval(interval); // Clean up on component unmount
  }, [navigate]);

  return null;
};

export default SessionCheck;
