import styled from '@emotion/styled'
import {Overlay} from "../../global_styles/global_styles"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function ImageModal({image, toggleImageModal}) {
    return(
        <>
            <ModalContainer>
                <TransformWrapper
                    initialScale={2}
                    initialPositionX={0}
                    initialPositionY={0}
                >
                    <TransformComponent
                        wrapperStyle={{height:'100%'}}
                    >
                        <img src={image}/>
                    </TransformComponent>
                </TransformWrapper>
            </ModalContainer>
            <div className={Overlay} onClick={toggleImageModal}></div>
        </>
    )
}

export default ImageModal

const ModalContainer = styled.div`
    height:80%;
    width:100%;
    background-color:white;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:1001;
    img{
        width:100%;
    }
`