export const feedback_strings = {
    content_overall:{
        0: "This is the feedback for a low content score",
        1: "This is the feedback for a mid content score",
        2: "This is the feedback for a high content score"
    },
    fluency_overall:{
        0: "This is the feedback for a low fluency score",
        1: "This is the feedback for a mid fluency score",
        2: "This is the feedback for a high fluency score"
    },
    pronunciation_overall:{
        0: "This is the feedback for a low pronunciation score",
        1: "This is the feedback for a mid pronunciation score",
        2: "This is the feedback for a high pronunciation score"
    },
    content_closed_ended:[
        {
            score: [0, 0.10],
            text: "You did not repeat the words"
        },
        {
            score: [0.10, 0.19],
            text: "You repeated back a few words"
        },
        {
            score: [0.20, 0.29],
            text: "You repeated back only a small number of words"
        },
        {
            score: [0.30, 0.39],
            text: "You repeated back a few words"
        },
        {
            score: [0.80, 0.49],
            text: "You repeated back a few words"
        },
        {
            score: [0.50, 0.59],
            text: "You repeated back some of the words"
        },
        {
            score: [0.60, 0.69],
            text: "You repeated back most of the words"
        },
        {
            score: [0.70, 0.79],
            text: "You repeated back most of the words"
        },
        {
            score: [0.80, 0.89],
            text: "You repeated back almost all of the words"
        },
        {
            score: [0.90, 0.99],
            text: "You repeated back all the words nearly perfectly"
        },
        {
            score: [1, 1],
            text: "You repeated back all the words perfectly"
        }
    ]
}