import './App.css';
import { RouterProvider } from 'react-router-dom';
import { Provider } from "react-redux";
import router from './routes/Router';
import store from './redux/store';
import { WebSocketProvider } from "./util/WebSocketProvider";


function App() {

  return (
    <Provider store={store}>
        <WebSocketProvider>
            <RouterProvider router={router}/>
        </WebSocketProvider>
    </Provider>
  );
}

export default App;
