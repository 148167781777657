import SERVER_URL from './SERVER_URL'

export const signup = async (formData) => {
    return fetch(`${SERVER_URL}/signup`, {
        method: "post",
        credentials: "include",
        headers:{
            "Content-Type": "application/json"
        },
        body:JSON.stringify(formData)
    })
}