import {types} from "../data/question_types"

export const getTimeString = (question_type) => {
    let typeMatch = types.filter(type=>type.type === question_type)
    let prepTime = typeMatch[0].prepTime ? typeMatch[0].prepTime : 0
    let recordTime = typeMatch[0].recordTime
    // let totalTime = prepTime + recordTime
    let totalTime = recordTime
    let minutes = Math.floor(totalTime / 60);
    if(minutes === 0){
        return `${totalTime} seconds`
    }else if(minutes > 0){
        let extraSeconds = totalTime % 60;
        return `${minutes} minute ${extraSeconds} seconds`
    }else{
        return 'unknown'
    }
}