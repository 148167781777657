import {scoring_thresholds} from "../data/scoring_thresholds.js"
import { content_objectives } from "../data/content_objectives"
import SERVER_URL from './SERVER_URL'

export const processAndSaveUIFeedback = async (pafData, contentData, typeData, questionData, feedbackId) => {

    let processedFeedback = {
        overallPercentage: 0,
        // contentObjectives: {},
        contentOverall: 0,
        contentOverallLabel: '',
        fluencyOverall: 0,
        fluencyOverallLabel: '',
        pronunciationOverall: 0,
        pronunciationOverallLabel: '',
        speed: '',
        hesitations: '',
        unexpectedPauses: '',
        repetitions: '',
        mispronunciations: '',
        wordsToImprove: [],
        // wordsMissed: []
    }
    let scoringCategory = typeData.scoringCategory
    let questionType = typeData.type
    let words = pafData['detailed']['words']


    //getFluencyOverall
    let fluency_thresholds = scoring_thresholds.fluency_overall
    let speechDuration = pafData['detailed']['duration'] 
    let pausesDuration = 0
    words.forEach(word => {
        if(word-1 >= 0){
            let prev_word = words[word-1] 
            pausesDuration+=(words[word].offset - (prev_word.offset + prev_word.duration))
        }
    })
    let overallFluencyScore = Number(speechDuration / (speechDuration + pausesDuration).toFixed(2))
    processedFeedback.fluencyOverall = overallFluencyScore
    if(overallFluencyScore >= fluency_thresholds.low[scoringCategory][0] && overallFluencyScore <= fluency_thresholds.low[scoringCategory][1]){
        processedFeedback.fluencyOverallLabel = fluency_thresholds.low.text
    }else if(overallFluencyScore >= fluency_thresholds.mid[scoringCategory][0] && overallFluencyScore <= fluency_thresholds.mid[scoringCategory][1]){
        processedFeedback.fluencyOverallLabel = fluency_thresholds.mid.text
    }else if(overallFluencyScore >= fluency_thresholds.high[scoringCategory][0] && overallFluencyScore <= fluency_thresholds.high[scoringCategory][1]){
        processedFeedback.fluencyOverallLabel = fluency_thresholds.high.text
    }

    //getPronunciationOverall
    let pronunciation_thresholds = scoring_thresholds.pronunciation_overall
    let phonemeAccuracyScores = [] 
    words.forEach(word => {
        phonemeAccuracyScores = phonemeAccuracyScores.concat(
            word.phonemes.map(phoneme => phoneme.accuracyScore)
        );
    })
    let mispronouncedPhonemes = phonemeAccuracyScores.filter(score => score < 80);
    let overallPronunciationScore = Number((phonemeAccuracyScores.length / (phonemeAccuracyScores.length + mispronouncedPhonemes.length)).toFixed(2))
    processedFeedback.pronunciationOverall = overallPronunciationScore
    if(overallPronunciationScore >= pronunciation_thresholds.low[scoringCategory][0] && overallPronunciationScore <= pronunciation_thresholds.low[scoringCategory][1]){
        processedFeedback.pronunciationOverallLabel = pronunciation_thresholds.low.text
    }else if(overallPronunciationScore >= pronunciation_thresholds.mid[scoringCategory][0] && overallPronunciationScore <= pronunciation_thresholds.mid[scoringCategory][1]){
        processedFeedback.pronunciationOverallLabel = pronunciation_thresholds.mid.text
    }else if(overallPronunciationScore >= pronunciation_thresholds.high[scoringCategory][0] && overallPronunciationScore <= pronunciation_thresholds.high[scoringCategory][1]){
        processedFeedback.pronunciationOverallLabel = pronunciation_thresholds.high.text
    }

    //getSpeed
    let duration = pafData['detailed']['duration']
    let speechDurationInMinutes =  duration / 600000000 
    let speed = words.length / speechDurationInMinutes 
    let speed_thresholds = scoring_thresholds.speed
    if(speed <= speed_thresholds.slow.score[0]){
        processedFeedback.speed = speed_thresholds.slow.text
    }else if(speed >= speed_thresholds.normal.score[0] && speed <= speed_thresholds.normal.score[1]){
        processedFeedback.speed = speed_thresholds.normal.text
    }else if(speed >= speed_thresholds.fast.score[0]){
        processedFeedback.speed = speed_thresholds.fast.text
    }

    //getHesitations
    let hesitations = 0;
    let sounds = ['uhm', 'um', 'uh']
    words.forEach(word => {
        if(sounds.includes(word.word)){
            hesitations += 1
        }   
    })
    processedFeedback.hesitations = hesitations

    //getUnexpectedPauses
    let pauses = []
    let longerPauses = 0
    words.forEach(word => {
        if(word-1 >= 0){
            let prev_word = words[word-1] 
            pauses.push((words[word].offset - (prev_word.offset + prev_word.duration)) / 10000000)
        }
    })
    pauses.forEach(pause=>{
        if(pause > 0.7){
            longerPauses += 1
        }
    })
    processedFeedback.unexpectedPauses = longerPauses

    //getRepetitions
    let repetitions = 0
    words.forEach(word => {
        if(word-1 >= 0){
            let prev_word = words[word-1] 
            if(word.word === prev_word.word){
                repetitions += 1
            }
        }
    })
    processedFeedback.repetitions = repetitions

    //getMispronunciations
    let mispronunciations = pafData['aggregated']['substitutions'].length
    let mispronunciation_thresholds = scoring_thresholds.mispronunciations
    if(mispronunciations <= mispronunciation_thresholds[0][scoringCategory][0]){
        processedFeedback.mispronunciations = mispronunciation_thresholds[0].text
    }else if(mispronunciations >= mispronunciation_thresholds[1][scoringCategory][0] && mispronunciations <= mispronunciation_thresholds[1][scoringCategory][1]){
        processedFeedback.mispronunciations = mispronunciation_thresholds[1].text
    }else if(mispronunciations >= mispronunciation_thresholds[2][scoringCategory][0] && mispronunciations <= mispronunciation_thresholds[2][scoringCategory][1]){
        processedFeedback.mispronunciations = mispronunciation_thresholds[2].text
    }
    else if(mispronunciations >= mispronunciation_thresholds[3][scoringCategory][0]){
        processedFeedback.mispronunciations = mispronunciation_thresholds[3].text
    }

    //getWordsToImprove
    let substitutions = pafData['aggregated']['substitutions']
    substitutions.forEach(sub=>{
        for(let word in sub['words']){
            let wordIndex = sub['words'][word].wordIndex
            let length = words[wordIndex]['syllables'].length
            if(length > 1){
                let newWord = {
                    word: sub['words'][word]['word'],
                    graphemeOffset: sub['words'][word]['graphemeOffset'],
                    graphemeLength: sub['words'][word]['graphemeLength']
                }
                if(!processedFeedback.wordsToImprove.some(word => word.word === newWord.word)){
                    processedFeedback.wordsToImprove.push(newWord)
                }
            }
        }
    })

    //content scores and breakdowns for open_ended and closed_ended question types 
    let contentOverallScore
    if(scoringCategory === "open_ended"){
        //getContentObjectives
        let keyPointsScores = contentData.content.keyPointsScores
        let objectives = [...content_objectives[questionData.id]]
        console.log(objectives)
        let co_threshold = questionData.co_threshold
        console.log(co_threshold)

        //Transfer scores to binary met/not met by comparing them with threshold
        objectives.forEach(objective=>{
            let scoreMatch = keyPointsScores.filter(keyPoint=>keyPoint.keyPoint === objective.objective)
            objective.score = scoreMatch[0].score
            objective.pass = Number(scoreMatch[0].score) >= co_threshold ? true : false
        })
        
        //Group content objectives by category
        let categoryIndex = 0;
        const objectivesByCat = objectives.reduce((acc, item) => {
            // Check if a category label already exists in the accumulator
            const existingCategory = Object.values(acc).find(obj => obj.label === item.category);
            
            // If category doesn't exist, add it with a new numeric key
            if (!existingCategory) {
                acc[categoryIndex] = {
                    label: item.category,
                    objectives: [],
                    pass: false
                };
                categoryIndex++;
            }
            
            // Find the numeric key associated with this category
            const key = Object.keys(acc).find(k => acc[k].label === item.category);
            
            // Add the item to the objectives array for that key
            acc[key].objectives.push(item);
            
            return acc;
        }, {});

        //Calculate number of met COs for every category 
        for (const category in objectivesByCat) {
            let count = 0
            // let category_threshold;
            objectivesByCat[category]["objectives"].forEach(objective=>{
                if(objective.pass){
                    count += 1
                }
            })

            // if(objectivesByCat[category].label === "Topic"){
            //     category_threshold = scoring_thresholds.cat_topic_threshold
            // }else{
            //     category_threshold = scoring_thresholds.cat_threshold
            // }

            // if(count >= category_threshold){
            //     objectivesByCat[category]["pass"] = true;
            // }
            
            objectivesByCat[category]["count"] = count
        }
        processedFeedback.contentObjectives = objectivesByCat

        // Equation for the Overall Content Score is: 
        // (min(cat1, c) + min(cat2, c) + min(cat3, c) + min(cat4, c)) / 8 
        // where c = 2 (Each category will be met or not met if there is at least 2 met content objectives)
        let equationItems = []
        for (const category in objectivesByCat) {
            equationItems.push(Math.min(objectivesByCat[category].count, scoring_thresholds.cat_minimum_threshold))
        }
        const sum = equationItems.reduce((acc, value) => acc + value, 0);
        let divisor = questionData.type === 'retell_lecture' ? 7 : 8
        contentOverallScore = sum / divisor
    }else if(scoringCategory === "closed_ended"){
        //get contetnt overall score for this scoring category
        contentOverallScore = contentData.content.responseMatchingScore

        //getWordsMissed
        let wordsMissed = []
        let vocabDetections = contentData.vocabAndDiscourse.vocabDetections
        vocabDetections.forEach((keyword)=>{
            if(keyword.isFound === false){
                wordsMissed.push(keyword.keyword)
            }
        })
        processedFeedback.wordsMissed = wordsMissed
    }

    processedFeedback.contentOverall = contentOverallScore

    let content_overall_thresholds = scoring_thresholds.content_overall
    if(contentOverallScore >= content_overall_thresholds.low.score[0] && contentOverallScore <= content_overall_thresholds.low.score[1]){
        processedFeedback.contentOverallLabel = content_overall_thresholds.low.text
    }else if(contentOverallScore >= content_overall_thresholds.mid.score[0] && contentOverallScore <= content_overall_thresholds.mid.score[1]){
        processedFeedback.contentOverallLabel = content_overall_thresholds.mid.text
    }else if(contentOverallScore >= content_overall_thresholds.high.score[0] && contentOverallScore <= content_overall_thresholds.high.score[1]){
        processedFeedback.contentOverallLabel = content_overall_thresholds.high.text
    }

    //getOverallPercentage
    let overall = ((processedFeedback.fluencyOverall + processedFeedback.pronunciationOverall + processedFeedback.contentOverall) / 3) * 100
    processedFeedback.overallPercentage =  Math.round(overall)
    


    let data = {
        processedFeedback: processedFeedback,
        feedbackId: feedbackId
    }

    return fetch(`${SERVER_URL}/feedback/save-processed`, {
        method: "post",
        credentials : "include",
        headers:{
            "Content-Type": "application/json"
        },
        body:JSON.stringify({data:data})
    })
    .then(response=>response.json())
    .then(data=>{
        if(data === "Processed feedback saved"){
            return processedFeedback
        }else {
            return data;
        }
    })
}