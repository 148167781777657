import { createSlice } from "@reduxjs/toolkit";

const userAttemptsSlice = createSlice({
    name: "userAttempts",
    initialState: [],
    reducers : {
        setUserAttempts : (state, action) => {
            state = action.payload
            return state
          }
    }
});
  
export const { setUserAttempts } = userAttemptsSlice.actions;

export default userAttemptsSlice.reducer;