import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
      if(Cookies.get('session_id')){
        setIsAuth(true);
      }else{
        setIsAuth(false);
      }
      setLoaded(true);
  }, [location.pathname]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  if (!isAuth) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;