import {useEffect, useState} from 'react'
import { cx, css } from '@emotion/css'
import {Page} from "../global_styles/global_styles"
import { Link, useParams, useLocation } from 'react-router-dom'
import {questions} from '../data/questions.js'
import Instructions from './Instructions'
import Listen from "./Listen"
import Task from './Task'
import Score from './Score.jsx'
import ContentDetail from './ContentDetail.jsx'
import {types} from "../data/question_types"

//images
import caretLeft from "../images/caret-left.svg"
import mixpanel from "mixpanel-browser";

function Question() {

    const location = useLocation()
    const params = useParams()
    const questionId = params.questionId
    
    const [questionData, setQuestionData] = useState(null)
    const [typeData, setTypeData] = useState(null)
    const [questionStep, setQuestionStep] = useState('instructions')
    const [showModal, setShowModal] = useState(false)
    const [showScore, setShowScore] = useState(params.score && params.score === "score" ? true : false)
    const [showContent, setShowContent] = useState(params.content && params.content === "content" ? true : false)

    const handleStart = () => {
        if(
            questionData.type === 'retell_lecture' || 
            questionData.type === 'repeat_sentence' ||
            questionData.type === 'short_question'
        ){
            setQuestionStep('listen')
        }else{
            setQuestionStep('task')
        }
    }

    const handleContinue = () => {
        setQuestionStep('task')
    }

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    useEffect(()=>{
        let questionFilter = questions.filter(question => question.id === Number(questionId))
        setQuestionData(questionFilter[0])
        // console.log(questionFilter[0])
        types.map((type, index)=>{
            if(type.type === questionFilter[0].type){
                setTypeData(type)
            }
        })
    },[questionId])

    useEffect(()=>{
        if(showScore){
            setQuestionStep('score')
        }
    },[showScore, location])

    useEffect(()=>{
        if(showContent){
            setQuestionStep('content-detail')
        }
    },[showContent, location])

    useEffect(()=>{
        if(params.score && params.score === "score"){
            setShowScore(true)
        }
    },[params])

    useEffect(()=>{
        if(params.content && params.content === "content"){
            setShowContent(true)
        }
    },[params])

    return (
        <Page className={cx(QuestionPage, questionStep === 'score' || questionStep === 'content-detail' ? WhiteBackground : '')} showModal={showModal}>
            {
                questionStep === 'content-detail' ?
                <a href={process.env.PUBLIC_URL + `/question/${questionId}/score`}
                    className={BackButton}
                >
                    <img src={caretLeft}/>
                    <span>Back to score</span>
                </a>
                :
                <Link to="/dashboard" className={BackButton}>
                    <img src={caretLeft}/>
                    <span>Back to questions</span>
                    onClick={() => mixpanel.track('Back to Question', { 'questionId': questionId })}
                </Link>
            }
            
            {
                questionStep === "instructions" ?
                <Instructions 
                    questionData={questionData}
                    handleStart={handleStart}
                    typeData={typeData}
                />
                : null
            }
            {
                questionStep === "listen" ?
                <Listen
                    questionData={questionData}
                    handleContinue={handleContinue}
                    typeData={typeData}
                />
                : null
            }
            {
                questionStep === "task" ?
                <Task
                    questionData={questionData}
                    questionId={questionId}
                    typeData={typeData}
                    handleShowModal={handleShowModal}
                />
                : null
            }
            {
                questionStep === "score" ?
                <Score
                    questionData={questionData}
                    questionId={questionId}
                    typeData={typeData}
                />
                : null
            }
            {
                questionStep === "content-detail" ?
                <ContentDetail
                    questionData={questionData}
                    questionId={questionId}
                    typeData={typeData}
                />
                : null
            }
        </Page>
    )
}

export default Question

const QuestionPage = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:24px;
`

const WhiteBackground = css`
    background: #FFF !important;
`

const BackButton = css`
    display:flex;
    align-items:center;
    gap:8px;
    span{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--color-text-primary);
    }
`