import SERVER_URL from './SERVER_URL'

export const startAttempt = async () => {
    return fetch(`${SERVER_URL}/attempt/start`, {
        method: "post",
        credentials : "include",
        headers:{
            "Content-Type": "application/json"
        },
        // body:JSON.stringify({data:data})
    })
}