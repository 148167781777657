import SERVER_URL from './SERVER_URL'

export const getUserAttempts = async () => {
    return fetch(`${SERVER_URL}/user-attempts`, {
        method: "get",
        credentials : "include",
        // headers:{
        //     "Content-Type": "application/json"
        // },
    })
}