import {useEffect, useState, createRef} from 'react'
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import {FocusTag, TextColorGreen, TextColorRed} from "../global_styles/global_styles"
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, RadialBarChart, RadialBar, PolarAngleAxis} from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import {addNewFeedback} from "../redux/feedbackSlice.js"

import passIcon from "../images/pass-icon.svg"
import failIcon from "../images/fail-icon.svg"
import arrowDown from "../images/arrow-down.svg"
import passageIcon from "../images/text-align-left.svg"
import wordsMissedIcon from "../images/chat-circle-dots.svg"

const breakdownData = [
    [{ name: 'Content', label: 'Low', value: 33, color: '#005FEE'}],
]

function ContentDetail({questionData, questionId, typeData}){

    const dispatch = useDispatch()

    const userAttempts = useSelector(state => state.userAttempts)
    const processedFeedback = useSelector(state => state.processedFeedback)

    const [feedback, setFeedback] = useState(null)
    const [accordionPanels, setAccordionPanels] = useState([])

    const loadFeedbackData = () => {
        let feedbackMatch = [];
        if(processedFeedback.length > 0){
            feedbackMatch = processedFeedback.filter(feedback => feedback.questionId === Number(questionId))
        }
        let attemptMatch = []
        if(userAttempts.length > 0){
            attemptMatch = userAttempts.filter(attempt => attempt.questionId === Number(questionId))
        }
        //Check if redux has processedFeedback for the given questionId (which it should if coming from Score.jsx)
        if(feedbackMatch[0]){
            setFeedback(feedbackMatch[0].feedback)
        //otherwise (if page reload) get the data from userAttempts and dispatch the feedback to redux
        }else if(attemptMatch[0]){
            let feedbackData = {
                questionId: Number(questionId),
                feedbackId: attemptMatch[0].latestFeedback._id,
                feedback: attemptMatch[0].latestFeedback.processedFeedback
            }
            dispatch(addNewFeedback(feedbackData))
        }
    }

    const handleAccordion = (button, index) => {
        if(button.getAttribute('aria-expanded') === 'true'){
            button.setAttribute('aria-expanded', false)
            accordionPanels[index].current.setAttribute('hidden', '');
        }else{
            button.setAttribute('aria-expanded', true)
            accordionPanels[index].current.removeAttribute('hidden');
        }
    }

    //load feedback data
    useEffect(()=>{
        if(!feedback){
            loadFeedbackData()
        }
    },[processedFeedback, userAttempts])

    useEffect(()=>{
        if(feedback && feedback.contentObjectives && Object.keys(feedback.contentObjectives).length > 0){
            setAccordionPanels(Object.keys(feedback.contentObjectives).map(()=>createRef()))
        }
    },[feedback])

    return(
        <>
            <ScoreContainer>
                <FocusTag>Focus area</FocusTag>
                <DetailHeader>
                    <h1>Content</h1>
                    <ScoreBreakdownChartRadial>
                        <RadialBarChart 
                            width={100} 
                            height={50} 
                            cx="50%"
                            cy="100%"
                            barSize={10}
                            innerRadius={42}
                            outerRadius={100}
                            data={breakdownData[0]} 
                            startAngle={180} 
                            endAngle={0}
                            isAnimationActive={true}
                            >
                                <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                    {breakdownData[0].map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                        />
                                    ))}
                                </RadialBar>
                                <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                        </RadialBarChart>
                        <CustomLabelRadial>
                            {breakdownData[0].map((entry, index) => (
                                entry.label
                            ))}
                        </CustomLabelRadial>
                    </ScoreBreakdownChartRadial>
                </DetailHeader>
                <DetailBody>
                    {
                        questionData.type === 'read_aloud' ?
                        <>
                            <DetailSectionLabel>
                                <img src={passageIcon}/>
                                <p>Passage provided</p>
                            </DetailSectionLabel>
                            <QuestionText>
                                {questionData.question.text}
                            </QuestionText>
                        </>
                        : null
                    }
                    {
                        questionData.type === 'read_aloud' || questionData.type === 'repeat_sentence'?
                        <>
                            <WordsMissedLabel>
                                <img src={wordsMissedIcon}/>
                                <p>Words you missed</p>
                            </WordsMissedLabel>
                            <WordsMissedList>
                                {
                                    feedback && feedback.wordsMissed && feedback.wordsMissed.length > 0 ?
                                    feedback.wordsMissed.map(word=>{
                                        return(
                                            <WordTag>
                                                    {word}
                                            </WordTag>
                                        )
                                    })
                                    : null
                                }
                            </WordsMissedList>
                        </>
                        : null
                    }
                    {
                        questionData.type === 'describe_image' ?
                        <QuestionImage>
                            <DetailSectionLabel>Question image</DetailSectionLabel>
                            <div>
                                <img src={questionData.question.image}/>
                            </div>
                        </QuestionImage>
                        : null
                    }
                    {
                        feedback && feedback.contentObjectives ? 
                            <AccordionList>
                                {Object.keys(feedback.contentObjectives).map((category, index) => (
                                    <AccordionGroup key={index}>
                                        <AccordionButton
                                            onClick={(e)=>{handleAccordion(e.target, index)}}
                                        >
                                            <div>
                                                <div className={`expanding-icon ${feedback.contentObjectives[index].pass ? IconGreen : IconRed}`}>
                                                    <img src={feedback.contentObjectives[index].pass ? passIcon : failIcon}/>
                                                    {
                                                        feedback.contentObjectives[index].pass ? 
                                                        <span className={TextColorGreen}>Perfect</span>
                                                        : 
                                                        <span className={TextColorRed}>Missed</span> 
                                                        
                                                    }
                                                </div>
                                                <AccordionButtonLabel>{feedback.contentObjectives[index].label}</AccordionButtonLabel>
                                            </div>
                                            <img src={arrowDown}/>
                                        </AccordionButton>
                                        <AccordionPanel
                                            ref={accordionPanels[index]}
                                            hidden={true}
                                        >
                                            {
                                                feedback.contentObjectives[index].pass ? 
                                                <>
                                                    <span className={TextColorGreen}>What went well</span>
                                                    <p>To meet this area, you included 2 or more of the following</p>
                                                </>
                                                : 
                                                <>
                                                    <span className={TextColorRed}>Points missed</span> 
                                                    <p>To meet this area, you would have needed to include any 2 of the following</p>
                                                </>
                                            }
                                            
                                            <ul>
                                                {
                                                    feedback.contentObjectives[index].objectives.map((objective, index)=>{
                                                        return(
                                                            <li key={index}>{objective.objective}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </AccordionPanel>
                                    </AccordionGroup>
                                ))}
                            </AccordionList>
                        : null
                    }
                    
                </DetailBody>
            </ScoreContainer>
        </>
    )
}

export default ContentDetail

const ScoreContainer = styled.div`
    margin-left: -16px;
    width: calc(100% + 32px);
    padding:16px;
`

const DetailHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:20px;
`

const ScoreBreakdownChartRadial = styled.div`
    position:relative;
`

const CustomLabelRadial = styled.span`
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    margin-top:12px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`

const DetailBody = styled.div`

`

const DetailSectionLabel = styled.div`
    display:flex;
    align-items:center;
    gap:4px;
    margin-bottom:12px;
    p{
        margin:0;
        color: var(--color-text-tertiary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px
    }
`

const QuestionText = styled.p`
    border-radius: 10px;
    background: var(--primary-colours-vet-blue-1-f-0-f-6-ff);
    padding:16px;
    font-size: var(--font-size-b1);
    line-height: var(--font-line-height-b1);
`

const QuestionImage = styled.div`
    width:100%;
    div{
        width:100%;
        cursor:pointer;
        img{
            width:100%;
            object-fit:contain;
        }
    }
`

const WordsMissedLabel = styled.div`
    display:flex;
    align-items:center;
    gap:4px;
    margin-bottom:12px;
    p{
        margin:0;
        color: var(--color-text-info);
        font-size: 18px;
        font-weight: 400;
        line-height: 24px
    }
`

const WordsMissedList = styled.div`

`

const WordTag = styled.span`

`

const AccordionList = styled.div`
    margin-top:26px;
`

const AccordionGroup = styled.div`
    border-radius: 12px;
    border: 1px solid var(--color-stroke-container-secondary);
    background: var(--color-fill-container-primary);
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-sm, 2px) var(--elevation-blur-xs, 4px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
    padding:16px;
    margin-bottom:8px;
`

const AccordionButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    div, img, span{
        pointer-events:none;
    }
    div{
        display:flex;
        align-items:center;
        gap:14px;
    }
    .expanding-icon{
        border-radius:80px;
        width:20px;
        height:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        span{
            display:none;
        }
    }
    &[aria-expanded="true"]{
        &>img{
            transform: rotate(180deg);
        }
        .expanding-icon{
            width:unset;
            padding: 2px 12px;
            img{
                display:none;
            }
            span{
                display:block;
            }
        }
    }    
`

const IconRed = css`
    background: var(--color-fill-error);
`

const IconGreen = css`
    background: var(--color-fill-success);
`

const AccordionButtonLabel = styled.span`
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
`

const AccordionPanel = styled.div`
    border-radius: 8px;
    background: var(--color-fill-section-secondary, #FAFAFA);
    padding:16px;
    margin-top:12px;
    span{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    p{
        margin: 8px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    ul{
        padding-inline-start:24px;
        margin-block-end: 0;
        li{
            list-style-type: disc;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
`