export const content_objectives = {
    10:[
        {
            objective: "This text mentions that four age groups are represented.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that the year 2020 is represented by orange color.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that the year 2010 is represented by blue color.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that the years 2010 and 2020 are compared.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that In the age group 0-20, the population decreased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that In the age group 21-40, the population increased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that In the age group 41-60, the population increased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that In the age group 61 and over, the population increased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that The age group 41-60 had the highest population in both 2010 and 2020.",
            category: "Use comparisons"
        },
        {
            objective: "This text mentions that The age group 0-20 is the only one that experienced a population decrease over the decade.",
            category: "Use comparisons"
        },
        {
            objective: "This text mentions that The most significant population growth was in the age group 61 and over.",
            category: "Use comparisons"
        },
        {
            objective: "This text mentions that The y-axis represents population numbers ranging from 0 to 80,000.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that The x-axis represents different age groups in the population.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that graph shows comparison of population numbers in a town by age group.",
            category: "Axis and labels"
        },
        {
            objective: "This text mentions that age group 0-20 went from 40,000 in 2010 to 30,000 in 2020.",
            category: "Numbers and specifics"
        },
        {
            objective: "This text mentions that age group 21-40 went from 32,000 in 2010 to 35,000 in 2020.",
            category: "Numbers and specifics"
        },
        {
            objective: "This text mentions that age group 41-60 went from 60,000 in 2010 to 75,000 in 2020.",
            category: "Numbers and specifics"
        },
        {
            objective: "This text mentions that age group 61 and over, went from 28,000 in 2010 to 43,000 in 2020.",
            category: "Numbers and specifics"
        }
    ],
    15:[
        {
            objective: "This text mentions the problem of orbital debris, also known as space junk.",
            category: "Topic"
        },
        {
            objective: "This text mentions that Earth orbit ranges from 160 to 2000 kilometers from Earth.",
            category: "Key points"
        },
        {
            objective: "This text mentions that Earth orbit become a space rubbish dump.",
            category: "Key points"
        },
        {
            objective: "This text mentions that millions of pieces of space junk are in orbit.",
            category: "Key points"
        },
        {
            objective: "This text mentions that space junk travels at speeds of 30,000 kilometers per hour.",
            category: "Key points"
        },
        {
            objective: "This text mentions that clearing up space debris is technically challenging.",
            category: "Key points"
        },
        {
            objective: "This text mentions that space junk consists of broken satellites.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that space junk consists of spacecraft parts.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that space junk consists of rockets.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that space junk travels at extremely high speeds.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that determining responsibility for cleaning up space junk is a significant issue.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that deciding what to do with the collected debris is a significant issue.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that high speed of space junk poses a risk to safety on Earth.",
            category: "Conclusions relationships"
        },
        {
            objective: "This text mentions that high speed of space junk poses a risk to future space missions.",
            category: "Conclusions relationships"
        },
        {
            objective: "This text mentions that the problem of space junk is an international challenge.",
            category: "Conclusions relationships"
        },
        {
            objective: "This text mentions that the problem of space junk requires global cooperation.",
            category: "Conclusions relationships"
        },
        {
            objective: "This text mentions that all nations involved in space travel share responsibility for addressing issue of space junk.",
            category: "Conclusions relationships"
        },
    ]
}