import { createContext, useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { savePAFFeedback } from "./savePAFFeedback";
import { getUserAttempts } from "./getUserAttempts";
import { setUserAttempts } from "../redux/userAttemptsSlice";
import {content_objectives} from "../data/content_objectives"
import { getContentFeedback } from "./getContentFeedback";
import {saveContentFeedback} from "./saveContentFeedback"
import WS_URL from "./WS_URL";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {

  const dispatch = useDispatch()

  let feedbackId, questionId;

  const webSocketState = useSelector(state => state.webSocketState)

  const [ws, setWs] = useState(null);
  const [open, setOpen] = useState(false)

  useEffect(()=>{
    if(webSocketState === 'open'){
      setOpen(true)
    }else if(webSocketState === 'closed'){
      setOpen(false)
    }
  },[webSocketState])

  useEffect(() => {
    if (open && !ws) {
        const session_id = Cookies.get('session_id'); 
        const socket = new WebSocket(`${WS_URL}?session_id=${session_id}`);
        setWs(socket);

        socket.onopen = () => {
            console.log("Connected to node WebSocket")
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            feedbackId = message.feedbackId
            questionId = message.questionId
            const pafEvaluation = message.pafEvaluation
            //save the raw P&F feedback to mongo database
            savePAFFeedback(message)
            .then(()=>{
              //refresh the user's data to see score page
              //to do: remove from here?
              getUserAttempts()
                .then(response=>response.json())
                .then(userAttempts=>{
                    dispatch(setUserAttempts(userAttempts))
                })
            })
            //get the transcript from the evaluation
            let transcript = pafEvaluation.detailed.displayText
            let objectives = []
            content_objectives[questionId].forEach(ob => {
                objectives.push(ob.objective)
            })
            let data = {
              scoringCategory: 'open_ended',
              text: transcript,
              objectives: objectives
            }
            //send to the content api
            getContentFeedback(data)
            .then(response=>response.json())
            .then(data=>{
                let contentData = {
                  feedbackId: feedbackId,
                  contentEvaluation: data
                }
                //save content feedback in mongo database
                saveContentFeedback(contentData)
            })
        };
          
        socket.onclose = () => console.log("Disconnected from node WebSocket");

        setWs(socket);

        return () => socket.close(); // Close WebSocket on cleanup

      } else if (!open && ws) {
        ws.close();
        setWs(null);
      }
  }, [open]);

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Hook to use WebSocket in any component
export const useWebSocket = () => useContext(WebSocketContext);