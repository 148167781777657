export const types = [
    {
       type: "read_aloud",
       typeName: "Read aloud",
       scoringCategory: "closed_ended",
       prepTime: 30,
       recordTime: 30,
       instructions: `
            <ol>
                <li>To prepare, get familiar with the text. (30 seconds)</li>
                <li>Read the text out loud, as naturally and clearly as possible. (30 seconds)</li>
            </ol>
       `,
       shortInstructions: {
            prep:{
                main: `<b>1. Get familiar with the text</b>`,
                sub: `<p>You have 30 seconds to prepare.</p>`
            },
            record: {
                main: `<b>2. Read the text out loud</b>`,
                sub: `<p>Recording in progress...</p>`
            }
        }
    },
    {
        type: "repeat_sentence",
        typeName: "Repeat sentence",
        scoringCategory: "closed_ended",
        recordTime: 15,
        instructions: `
            <ol>
                <li>Play the audio to listen to a sentence. <b>You will hear the sentence only once.</b></li>
                <li>Repeat the sentence exactly as you hear it.</li>
            </ol>
        `
    },
    {
        type: "describe_image",
        typeName: "Describe image",
        scoringCategory: "open_ended",
        prepTime: 25,
        recordTime: 40,
        instructions: `
            <ol>
                <li>To prepare, observe the image and analyze what it is showing. (25 seconds)</li>
                <li>Describe the image. (40 seconds)</li>
            </ol>
        `,
        shortInstructions: {
            prep:{
                main: `<b>1. Observe the image</b>`,
                sub: `<p>You have 25 seconds to prepare.</p>`
            },
            record: {
                main: `<b>2. Describe the image</b>`,
                sub: `<p>Recording in progress...</p>`
            }
        }
    },
    {
        type: "retell_lecture",
        typeName: "Retell lecture",
        scoringCategory: "open_ended",
        prepTime: 10,
        recordTime: 40,
        instructions: `
            <ol>
                <li>Listen to a lecture and take notes. <b>You will hear the lecture only once.</b> (60-90 seconds)</li>
                <li>Prepare to retell the lecture. (10 seconds)</li>
                <li>Retell the lecture in your own words. (40 seconds)</li>
            </ol>
        `,
        shortInstructions: {
            listen:{
                main: `<b>1. Listen to the lecture</b>`,
                sub: `<p>To begin, play the audio.</p>`
            },
            prep:{
                main: `<b>2. Prepare to retell the lecture</b>`,
            },
            record: {
                main: `<b>3. Retell the lecture</b>`,
                sub: `<p>Recording in progress...</p>`
            }
        }
    },
    {
        type: "short_question",
        typeName: "Answer short question",
        scoringCategory: "closed_ended",
        recordTime: 10,
        instructions: `
            <ol>
                <li>Play the audio to listen to a question. <b>You will hear the question only once.</b></li>
                <li>Give a short answer. One or a few words is enough.</li>
            </ol>
        `
    }
]