import SERVER_URL from './SERVER_URL'

export const getContentFeedback = async (data) => {
    return fetch(`${SERVER_URL}/content`, {
        method: "post",
        credentials : "include",
        headers:{
            "Content-Type": "application/json"
        },
        body:JSON.stringify({data:data})
    })
}