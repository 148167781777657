import {useState, useEffect} from 'react'
import SERVER_URL from '../util/SERVER_URL'
import { getContentFeedback } from '../util/getContentFeedback'
import { useWebSocket } from '../util/WebSocketProvider'
import {content_objectives} from "../data/content_objectives"

function TestAPI(){

    const text = "This graph compares the population numbers in ferndown by age group in 2010 and in 2020. So 2020 is represented by the orange color. In 2010 is represented by the blue color, so for example in 2010. From the age between 0 and 20 years old has a population of 40,000 people, whereas in in 2020 this age group only had a population of 30,000 people. So you can see that from 2010 to 2020 in the age gap of 0 to 20 there was a decrease, but on the other age gaps there was an increase of population."
    const sample_objectives = content_objectives[10]
    const objectives_only = []

    const parseObjectives = () => {
        sample_objectives.forEach(ob => {
            objectives_only.push(ob.objective)
        })
    }

    useEffect(()=>{
        parseObjectives(sample_objectives)
    },[sample_objectives])
    
    
    const testApiFunc = () => {
        let data = {
            text: text,
            objectives: objectives_only
        }
        getContentFeedback(data)
        .then(response=>response.json())
        .then(data=>console.log(data))
    }

    // const ws = useWebSocket();

    // const sendMessage = (message) => {
    //     if (ws && ws.readyState === WebSocket.OPEN) {
    //       ws.send(JSON.stringify(message));
    //     }
    //   };

    let url = 'https://apigw-us-gip-01.pgi.pearsonprd.tech/v2/dictionaries/entries?headword=Twenty'
    // let url = 'https://apigw-us-gip-01.pgi.pearsonprd.tech/v2/dictionaries/entries/csTMdAsRKk'
    // let url = 'https://apigw-us-gip-01.pgi.pearsonprd.tech/v2/dictionaries/assets/ldoce/us_pron/twenty.mp3'

    const testDictionary = () => {
        fetch(url, {
            method: "get",
            // credentials : "include",
            headers:{
                // "Content-Type": "application/json",
                "apikey":"72a9ad2e498f48f2aff0e046091e01af"
            },
            // body:JSON.stringify({data:data})
        })
        .then(response=>response.json())
        .then(data=>console.log(data))
    }
    

    return(
        <>
            <p>test api</p>
            <button onClick={testDictionary}>testAPI</button>
        </>
    )
}

export default TestAPI