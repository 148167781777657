export const scoring_thresholds = {
    fluency_overall: {
        low: {
            text: "Low",
            open_ended: [0, 0.75],
            closed_ended: [0, 0.80]
        },
        mid:{
            text: "Mid",
            open_ended: [0.76, 0.85],
            closed_ended: [0.81, 0.95]
        },
        high:{
            text: "High",
            open_ended: [0.86, 1],
            closed_ended: [0.96, 1]
        }
    },
    pronunciation_overall: {
        low: {
            text: "Low",
            open_ended: [0, 0.75],
            closed_ended: [0, 0.85]
        },
        mid:{
            text: "Mid",
            open_ended: [0.76, 0.85],
            closed_ended: [0.86, 0.95]
        },
        high:{
            text: "High",
            open_ended: [0.86, 1],
            closed_ended: [0.96, 1]
        }
    },
    content_overall: {
        low: {
            text: "Low",
            score: [0, 0.32],
        },
        mid:{
            text: "Mid",
            score: [0.33, 0.65],
        },
        high:{
            text: "High",
            score: [0.66, 1]
        }
    },
    speed: {
        slow: {
            text: "Slower than average",
            score: [99]
        },
        normal: {
            text: "Normal",
            score: [100, 150]
        },
        fast: {
            text: "Quicker than average",
            score: [151]
        },
    },
    hesitations: {
        max: 2
    },
    unexpected_pauses:{
        max: 4
    },
    repetitions: {
        max: 2
    },
    mispronunciations:{
        0:{
            text: "None",
            open_ended: [0],
            closed_ended: [0]
        },
        1:{
            text: "A few",
            open_ended: [1, 15],
            closed_ended: [1, 5]
        },
        2:{
            text: "Some",
            open_ended: [16, 30],
            closed_ended: [6, 15]
        },
        3:{
            text: "Many ",
            open_ended: [31],
            closed_ended: [16]
        }
    },
    cat_thresholds: {
        0:{
            text: "Missed",
            score: 0,
        },
        1:{
            text: "Almost",
            score: 1,
        },
        2:{
            text: "Perfect",
            score: 2,
        },
    },
    cat_minimum_threshold: 2,
    cat_topic_threshold: 1,
}