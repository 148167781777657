import SERVER_URL from './SERVER_URL'

// export const signout = async () => {
//     return fetch(`${SERVER_URL}/signout`)
// }

export const signout = async (sessionDetailId) => {
    return fetch(`${SERVER_URL}/signout`, {
        method: "post",
        // credentials : "include",
        headers:{
            "Content-Type": "application/json"
        },
        body:JSON.stringify({data: {sessionDetailId: sessionDetailId}})
    })
}