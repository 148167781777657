import {useEffect, useState, useRef, createRef} from 'react'
import { useNavigate } from 'react-router-dom';
import {TaskContainer, FocusTag, TextColorGreen, TextColorOrange, TextColorRed, TextColorBlue} from "../global_styles/global_styles"
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import {getTypeProperName} from "../util/getTypeProperName.js"
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, RadialBarChart, RadialBar, PolarAngleAxis} from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { processAndSaveUIFeedback } from '../util/processAndSaveUIFeedback.js'
import {addNewFeedback, updateFeedback} from "../redux/feedbackSlice.js"
import { feedback_strings } from '../data/feedback_strings.js'
import { scoring_thresholds } from '../data/scoring_thresholds.js';

import questionIcon from "../images/question_pink.svg"
import speaker from '../images/speaker.svg'
import check from "../images/check-circle.svg"

function Score({questionData, questionId, typeData}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const root = document.documentElement;    
    const colorBrandPrimary50Inverse = getComputedStyle(root).getPropertyValue('--color-brand-primary-50-inverse');   
    const colorLow = getComputedStyle(root).getPropertyValue('--score-low');   
    const colorMid = getComputedStyle(root).getPropertyValue('--score-mid');   
    const colorHigh = getComputedStyle(root).getPropertyValue('--score-high');   


    const userAttempts = useSelector(state => state.userAttempts)
    const processedFeedback = useSelector(state => state.processedFeedback) //feedback for all questions attempted

    const [feedback, setFeedback] = useState(null) //processed feedback for this question only
    const [overallData, setOverallData] = useState([])
    const [breakdownData, setBreakdownData] = useState([])
    const [pronunciationAudio, setPronunciationAudio] = useState([])
    const [pronunciationAudioNodes, setPronunciationAudioNodes] = useState([])

    const loadFeedbackData = async () => {
        
        let feedbackMatch = [];
        if(processedFeedback.length > 0){
            feedbackMatch = processedFeedback.filter(feedback => feedback.questionId === Number(questionId))
        }
        let attemptMatch = []
        if(userAttempts.length > 0){
            attemptMatch = userAttempts.filter(attempt => attempt.questionId === Number(questionId))
        }

        //Check if redux has processedFeedback for the given questionId
        if(feedbackMatch[0]){
            //Yes?
            //check feedbackId against latestFeedback id to see if this is actually the latestest feedback or if new feedback is available
            if(feedbackMatch[0].feedbackId === attemptMatch[0].latestFeedback._id){
                //ids are the same? save to state and render
                setFeedback(feedbackMatch[0].feedback)
            }else{
                //ids don't match, need to process new feedback and update in redux
                //first make sure the evaluations are available to proccess
                if(attemptMatch[0].latestFeedback.pafEvaluation && attemptMatch[0].latestFeedback.contentEvaluation){
                    processFeedback(attemptMatch[0].latestFeedback, 'update')
                }
            }
        }else{
            //No processedFeedback?
            //First check if latestfeedback has processedFeedback and it just isn't saved in redux
            if(attemptMatch[0]){
                if(attemptMatch[0].latestFeedback.processedFeedback){
                    //Yes? dispatch save to redux which should trigger loadFeedbackData to run again
                    let feedbackData = {
                        questionId: Number(questionId),
                        feedbackId: attemptMatch[0].latestFeedback._id,
                        feedback: attemptMatch[0].latestFeedback.processedFeedback
                    }
                    dispatch(addNewFeedback(feedbackData))
                //Still No? then check if latestfeedback has pasEval and contentEval    
                }else if(!attemptMatch[0].latestFeedback.processedFeedback && attemptMatch[0].latestFeedback.pafEvaluation && attemptMatch[0].latestFeedback.contentEvaluation){
                    processFeedback(attemptMatch[0].latestFeedback, 'add')
                }else{
                    //show loading or error here?
                }
            }
        }
    }

    const processFeedback = (latestFeedback, reduxAction) => {
        let pafData = latestFeedback.pafEvaluation
        let contentData = latestFeedback.contentEvaluation
        let feedbackId = latestFeedback._id
        processAndSaveUIFeedback(pafData, contentData, typeData, questionData, feedbackId)
        .then(result => {
            let feedbackData = {
                questionId: Number(questionId),
                feedbackId: latestFeedback._id,
                feedback: result
            }
            //then save to redux which should trigger the useEffect to run again
            if(reduxAction === 'add'){
                dispatch(addNewFeedback(feedbackData))
            }else if(reduxAction === 'update'){
                dispatch(updateFeedback(feedbackData))
            }
            
        })
        
    }

    const getBreakdownValue = (label) => {
        if(label === "Low"){
            return 33
        }else if(label === "Mid"){
            return 66
        }else if(label === "High"){
            return 100
        }else{
            return 0
        }
    }

    const getBreakdownColor = (label) => {
        if(label === "Low"){
            return colorLow
        }else if(label === "Mid"){
            return colorMid
        }else if(label === "High"){
            return colorHigh
        }else{
            return 0
        }
    }

    const getFeedbackString = (topic, score) => {
        let topicGroup = feedback_strings[`${topic}_overall`] 
        if(score === "Low"){
            return topicGroup[0]
        }else if(score === "Mid"){
            return topicGroup[1]
        }else if(score === "High"){
            return topicGroup[2]
        }
    }

    const getContentCardFeedback = () => {
        let returnText = ""
        feedback_strings.content_closed_ended.forEach(range => {
            if(feedback.contentOverall >= range.score[0] && feedback.contentOverall <= range.score[1]){
                returnText = range.text
            }
        })
        return returnText
    }

    const getPronunciationAudio = () => {
        let mispronunciations = feedback.wordsToImprove
        mispronunciations.forEach((word)=>{
            let newWord = {...word}
            let dictionaryUrl = `https://apigw-us-gip-01.pgi.pearsonprd.tech/v2/dictionaries/entries?headword=${word.word}`
            fetch(dictionaryUrl, {
                method: "get",
                headers:{
                    "apikey": process.env.REACT_APP_DICTIONARY_KEY
                },
            })
            .then(response=>response.json())
            .then(data=>{
                if(data.results.length > 0){
                    data.results.forEach(result=>{
                        if(result.pronunciations && result.pronunciations[0].audio && result.pronunciations[0].audio[0]){
                            newWord.audioUrl = 'https://apigw-us-gip-01.pgi.pearsonprd.tech' + result.pronunciations[0].audio[0].url
                            setPronunciationAudio(prev=>{
                                if(!prev.some(word => word.word === newWord.word) && prev.length < 3){
                                    return [...prev, newWord]
                                }else{
                                    return prev
                                }
                            })
                        }
                    })
                }
            })
        })
    }

    const handlePlayPronunciationAudio = (index) => {
        pronunciationAudioNodes[index].current.play()
    }

    //load feedback data
    useEffect(()=>{
        if(!feedback){
            loadFeedbackData()
        }
    },[processedFeedback, userAttempts])

    //load recharts data
    useEffect(()=>{
        if(feedback){

            setOverallData(
                [
                    { name: 'Score', value: feedback.overallPercentage, color: colorBrandPrimary50Inverse},
                    { name: 'Background', value: (100 - feedback.overallPercentage), color: 'transparent'},
                ]
            )
            setBreakdownData(
                [
                    [{ name: 'Content', label: feedback.contentOverallLabel, value: getBreakdownValue(feedback.contentOverallLabel), color: getBreakdownColor(feedback.contentOverallLabel)}],
                    [{ name: 'Fluency', label: feedback.fluencyOverallLabel, value: getBreakdownValue(feedback.fluencyOverallLabel), color: getBreakdownColor(feedback.fluencyOverallLabel)}],
                    [{ name: 'Pronunciation', label: feedback.pronunciationOverallLabel, value: getBreakdownValue(feedback.pronunciationOverallLabel), color: getBreakdownColor(feedback.pronunciationOverallLabel)}]
                ]
            )

            getPronunciationAudio()
        }
    },[feedback])

    useEffect(()=>{
        if(pronunciationAudio.length > 0){
            setPronunciationAudioNodes(pronunciationAudio.map(()=>createRef()))
        }
    },[pronunciationAudio])

    return(
        <>
        
            {
                !feedback ?
                <TaskContainer>
                    <LoadingContainer>
                        <LoaderAnimation>
                            <span className='first'></span>
                            <span className='middle'></span>
                            <span className='last'></span>
                        </LoaderAnimation>
                        <LoadingTitle>Your answer is being scored</LoadingTitle>
                        <LoadingSubtitle>This may take 20 seconds</LoadingSubtitle>
                    </LoadingContainer>
                </TaskContainer>
                :
                <ScoreContainer>
                    <ScoreH1>{getTypeProperName(questionData.type)}</ScoreH1>
                    {/* <LevelTag>
                        <p>{questionData.level} level</p>
                        <img src={questionIcon}/>
                    </LevelTag> */}
                    <OverallScore>
                    <OverallScoreChart>
                        <PieChart className="pie" width={100} height={100} >
                            <Pie
                                data={overallData}
                                cx="50%"
                                cy="50%"
                                innerRadius={41}
                                outerRadius={49}
                                cornerRadius={10}
                                dataKey="value"
                                paddingAngle={5}
                                startAngle={90} 
                                endAngle={-270}
                                isAnimationActive={true}
                                >
                                {overallData.map((entry, index) => (
                                    <Cell 
                                        key={`cell-${index}`}
                                        stroke={entry.color}
                                        fill={entry.color}
                                    />
                                ))}
                                </Pie>
                            </PieChart>
                            <svg className="pieBackground" xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
                                <path d="M100 50.5C100 78.1142 77.6142 100.5 50 100.5C22.3858 100.5 0 78.1142 0 50.5C0 22.8858 22.3858 0.5 50 0.5C77.6142 0.5 100 22.8858 100 50.5ZM10 50.5C10 72.5914 27.9086 90.5 50 90.5C72.0914 90.5 90 72.5914 90 50.5C90 28.4086 72.0914 10.5 50 10.5C27.9086 10.5 10 28.4086 10 50.5Z" fill="#FAFAFA"/>
                            </svg>
                            <CustomLabelPie>
                                <span>
                                    {feedback.overallPercentage}%
                                </span>
                            </CustomLabelPie>
                        </OverallScoreChart>
                        <h2>Overall score</h2>
                    </OverallScore>
                    {
                        breakdownData.map((dataItem, index) => {
                            return(
                                <ScoreBreakdownCard key={index}>
                                    <ScoreBreakdownChart>
                                        <CustomLabelLeft>
                                            {dataItem.map((entry, index) => (
                                                entry.name
                                            ))}
                                        </CustomLabelLeft>
                                        <BarChart 
                                            width={200}
                                            height={20}
                                            barSize={12}
                                            data={dataItem}
                                            layout="vertical"
                                        >
                                            <XAxis type="number" hide="true" domain={[0, 100]}/>
                                            <YAxis type="category" hide="true"/>
                                            <Bar 
                                                dataKey="value"
                                                fill='red'
                                                background={{ fill: '#eee', radius: 12 }}
                                                radius={12}
                                            >
                                                {dataItem.map((entry, index) => (
                                                    <Cell 
                                                        key={`cell-${index}`}
                                                        fill={entry.color}
                                                    />
                                                ))}
                                            </Bar>
                                        </BarChart>
                                        <CustomLabelRight>
                                            {dataItem.map((entry, index) => (
                                                entry.label
                                            ))}
                                        </CustomLabelRight>
                                    </ScoreBreakdownChart>
                                    <p>{getFeedbackString(dataItem[0].name.toLowerCase(), dataItem[0].label)}</p>
                                </ScoreBreakdownCard>
                            )
                        })
                    }
                    <DetailCard
                        className={ContentDetailCard}
                        onClick={()=>{
                            navigate(process.env.PUBLIC_URL + `/question/${questionId}/score/content`)
                        }}
                    >
                        <FocusTag>Focus area</FocusTag>
                        <DetailCardHeader>
                            <h2>Content</h2>
                            {
                                breakdownData.length > 0 ? 
                                <ScoreBreakdownChartRadial>
                                    <RadialBarChart 
                                        width={100} 
                                        height={50} 
                                        cx="50%"
                                        cy="100%"
                                        barSize={10}
                                        innerRadius={42}
                                        outerRadius={100}
                                        data={breakdownData[0]} 
                                        startAngle={180} 
                                        endAngle={0}
                                        isAnimationActive={true}
                                        >
                                            <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                                {breakdownData[0].map((entry, index) => (
                                                    <Cell 
                                                        key={`cell-${index}`}
                                                        fill={entry.color}
                                                    />
                                                ))}
                                            </RadialBar>
                                            <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                    </RadialBarChart>
                                    <CustomLabelRadial>
                                        {breakdownData[0].map((entry, index) => (
                                            entry.label
                                        ))}
                                    </CustomLabelRadial>
                                </ScoreBreakdownChartRadial>
                                : null
                            }
                            
                        </DetailCardHeader>
                        {
                            feedback.contentObjectives ?
                            <MetricList>
                                {Object.keys(feedback.contentObjectives).map((category, index) => (
                                    <MetricListItem key={index} className={css(feedback.contentObjectives[index].pass ? ListItemGreen : ListItemRed)}>
                                            <span>{feedback.contentObjectives[index].label}</span>
                                            <span className={css(feedback.contentObjectives[index].pass ? TextColorGreen : TextColorRed)} >{feedback.contentObjectives[index].pass ? 'Perfect' : 'Missed'}</span>
                                    </MetricListItem>
                                ))}
                            </MetricList>
                            : null
                        }
                        {
                            typeData.type === "read_aloud" || typeData.type === "repeat_sentence" ?
                            <>
                                <ContentFeedbackPos className={ListItemGreen}>
                                    <p className={ListItemLabelGreen}>
                                        <img src={check}/>
                                        <span>What went well</span>
                                    </p>
                                    <p>{getContentCardFeedback()}</p>
                                </ContentFeedbackPos>
                            </> 
                            : null
                        }
                        
                        <DetailCardFooter>More Detail</DetailCardFooter>
                    </DetailCard>
                    <DetailCard>
                        <DetailCardHeader>
                            <h2>Fluency</h2>
                            {
                                breakdownData.length > 0 ? 
                                <ScoreBreakdownChartRadial>
                                    <RadialBarChart 
                                        width={100} 
                                        height={50} 
                                        cx="50%"
                                        cy="100%"
                                        barSize={10}
                                        innerRadius={42}
                                        outerRadius={100}
                                        data={breakdownData[1]} 
                                        startAngle={180} 
                                        endAngle={0}
                                        isAnimationActive={true}
                                        >
                                            <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                                {breakdownData[1].map((entry, index) => (
                                                    <Cell 
                                                        key={`cell-${index}`}
                                                        fill={entry.color}
                                                    />
                                                ))}
                                            </RadialBar>
                                            <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                    </RadialBarChart>
                                    <CustomLabelRadial>
                                        {breakdownData[1].map((entry, index) => (
                                            entry.label
                                        ))}
                                    </CustomLabelRadial>
                                </ScoreBreakdownChartRadial>
                                : null
                            }
                        </DetailCardHeader>
                        <MetricList>
                            <MetricListItem className={css(feedback.speed === 'Normal' ? ListItemGreen : ListItemBlue)}>
                                    <span>
                                        Speed
                                        <img className='tooltip-speed' src={questionIcon}/>
                                    </span>
                                    <span className={css(feedback.speed === 'Normal' ? TextColorGreen : TextColorBlue)}>
                                        {feedback.speed}
                                    </span>
                            </MetricListItem>
                            <MetricListItem className={css(feedback.hesitations === 0 ? ListItemGreen : feedback.hesitations === 1 ? ListItemOrange : ListItemRed)}>
                                    <span>
                                        Hesitations
                                        <img className='tooltip-hesitations' src={questionIcon}/>
                                    </span>
                                    <span className={css(feedback.hesitations === 0  ? TextColorGreen : feedback.hesitations >= 1 && feedback.hesitations <= scoring_thresholds.hesitations.max ? TextColorOrange : TextColorRed)}>
                                    {feedback.hesitations > scoring_thresholds.hesitations.max ? scoring_thresholds.hesitations.max + '+' : feedback.hesitations}
                                    </span>
                            </MetricListItem>
                            <MetricListItem className={css(feedback.unexpectedPauses <= 1 ? ListItemGreen : feedback.unexpectedPauses >= 2 && feedback.unexpectedPauses <= scoring_thresholds.unexpected_pauses.max ? ListItemOrange : ListItemRed)}>
                                    <span>
                                        Unexpected pauses
                                        <img className='tooltip-pauses' src={questionIcon}/>
                                    </span>
                                    <span className={css(feedback.unexpectedPauses <= 1 ? TextColorGreen : feedback.unexpectedPauses >= 2 && feedback.unexpectedPauses <= scoring_thresholds.unexpected_pauses.max ? TextColorOrange : TextColorRed)}>
                                        {feedback.unexpectedPauses > scoring_thresholds.unexpected_pauses.max ? scoring_thresholds.unexpected_pauses.max + '+' : feedback.unexpectedPauses}
                                    </span>
                            </MetricListItem>
                            <MetricListItem className={css(feedback.repetitions === 0 ? ListItemGreen : feedback.repetitions === 1 ? ListItemOrange : ListItemRed)}>
                                    <span>
                                        Repetitions
                                        <img className='tooltip-repetitions' src={questionIcon}/>
                                    </span>
                                    <span className={css(feedback.repetitions === 0  ? TextColorGreen : feedback.repetitions >= 1 && feedback.repetitions <= scoring_thresholds.repetitions.max ? TextColorOrange : TextColorRed)}>
                                    {feedback.repetitions > scoring_thresholds.repetitions.max ? scoring_thresholds.repetitions.max + '+' : feedback.repetitions}
                                    </span>
                            </MetricListItem>
                        </MetricList>
                    </DetailCard>
                    <DetailCard>
                        <DetailCardHeader>
                            <h2>Pronunciation</h2>
                            {
                                breakdownData.length > 0 ? 
                                <ScoreBreakdownChartRadial>
                                    <RadialBarChart 
                                        width={100} 
                                        height={50} 
                                        cx="50%"
                                        cy="100%"
                                        barSize={10}
                                        innerRadius={42}
                                        outerRadius={100}
                                        data={breakdownData[2]} 
                                        startAngle={180} 
                                        endAngle={0}
                                        isAnimationActive={true}
                                        >
                                            <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                                {breakdownData[2].map((entry, index) => (
                                                    <Cell 
                                                        key={`cell-${index}`}
                                                        fill={entry.color}
                                                    />
                                                ))}
                                            </RadialBar>
                                            <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                    </RadialBarChart>
                                    <CustomLabelRadial>
                                        {breakdownData[2].map((entry, index) => (
                                            entry.label
                                        ))}
                                    </CustomLabelRadial>
                                </ScoreBreakdownChartRadial>
                                : null
                            }
                        </DetailCardHeader>
                        <MetricList>
                            <MetricListItem className={css(feedback.mispronunciations === 'A few' || feedback.mispronunciations === 'Some' ? ListItemOrange : feedback.mispronunciations === 'None' ? ListItemGreen : ListItemRed)}>
                                    <span>Mispronunciations</span>
                                    <span className={css(feedback.mispronunciations === 'A few' || feedback.mispronunciations === 'Some' ? TextColorOrange : feedback.mispronunciations === 'None' ? TextColorGreen : TextColorRed)}>
                                        {feedback.mispronunciations}
                                    </span>
                            </MetricListItem>
                        </MetricList>
                        <WordsListContainer>
                            <h3>Words you mispronounced</h3>
                            <p>Listen to the audio and pay attention to the highlighted sounds.</p>
                            <WordsList>
                                {
                                    pronunciationAudio.map((word, index)=>{
                                        return(
                                            <WordsListItem key={index}>
                                                    <button onClick={()=>{handlePlayPronunciationAudio(index)}}>
                                                        <span>{word.word}</span>
                                                        <img src={speaker}/>
                                                    </button>
                                                    <audio 
                                                        ref={pronunciationAudioNodes[index]}
                                                    >
                                                        <source src={word.audioUrl} type="audio/mpeg"/>
                                                    </audio>
                                            </WordsListItem>
                                        )
                                    })
                                }
                            </WordsList>
                        </WordsListContainer>
                    </DetailCard>
                </ScoreContainer>
            }
            
        </>
    )
}

export default Score

const bounce = keyframes`
    0%,30%{
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
    50%  {
        transform:translate(0, -8px);
        background: var(--color-brand-primary-50);
    }
    100% {
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
`

const LoadingContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:100%;
`

const LoaderAnimation = styled.div`
    margin:16px;
    position: relative;
    float:left;
    // width: 62px;
    height: 32px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:6px;
    padding: 0 16px;
    box-sizing:border-box;
    span{
        display:block;
        height:8px;
        width:8px;
        border-radius:8px;
        background: var(--color-monochrome-15);
        animation: ${bounce} 2s infinite ease;
        &.first{
            animation-delay:200ms;
        }
        &.middle{
            animation-delay:500ms;
        }
        &.last{
            animation-delay:800ms;
        }
    }
`

const LoadingTitle = styled.p`
    color: var(--color-text-primary);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin:0;
    text-align:center;
`

const LoadingSubtitle = styled.p`
    color: var(--Neutrals---color-grayscale-070);
    font-size: 20px
    font-weight: 600;
    line-height: 28px;
    margin-top:16px;
    text-align:center;
`

const ScoreContainer = styled.div`
    margin-left: -16px;
    width: calc(100% + 32px);
    padding:16px;
`

const ScoreH1 = styled.h1`
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
`

const LevelTag = styled.div`
    border-radius: 8px;
    background: var(--color-fill-section-secondary);
    padding:10px;
    width:fit-content;
    margin-top:8px;
    display:flex;
    align-items:center;
    gap:10px;
    p{
        margin: 0;
    }
`

const OverallScore = styled.div`
    display:flex;
    align-items:center;
    gap:24px;
    margin-top:45px;
    margin-bottom:45px;
    h2{
        width:100%;
        text-align:center;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        color: #000;
    }
`

const OverallScoreChart = styled.div`
    height: 100px;
    width:100px;
    position:relative;
    .pie{
        z-index:2;
        // transform:rotate(90deg);
    }
    .pieBackground{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        z-index:1;
    }
`

const CustomLabelPie = styled.div`
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  span{
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    line-height: 160%; 
    color: #000;
  }
`

const ScoreBreakdownCard = styled.div`  
    border-radius: 8px;
    background: var(--color-fill-section-secondary);
    padding:8px;
    margin-bottom:12px;
    &>p{
       margin:0;
       margin-top:18px;
    }
`

const ScoreBreakdownChart = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    gap:8px;    
`

const CustomLabelLeft = styled.p`
    margin:0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    width: 124px;
`

const CustomLabelRight = styled.p`
    margin:0;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
    text-align:right;
`

const DetailCard = styled.div`
    min-height:300px;
    border-radius: 12px;
    background: #FFF;
    margin-top:45px;
    padding:24px;
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-2xl, 24px) var(--elevation-blur-3xl, 48px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
`

const ContentDetailCard = css`
    cursor:pointer;
    border: 1px solid var(--color-stroke-interactive-focus);
`

const DetailCardHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    h2{
        color: #000;
    }
`

const DetailCardFooter = styled.p`
    color: var(--color-text-interactive-brand-default);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin:0;
    margin-top:24px;
    width:100%;
    text-align:center;
`

const ScoreBreakdownChartRadial = styled.div`
    position:relative;
`

const CustomLabelRadial = styled.span`
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    margin-top:12px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`

const MetricList = styled.ul`
    margin-top:24px;
`

const MetricListItem = styled.li`
    padding: 8px 16px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-radius: 8px;
    margin-bottom:8px;
    span:first-of-type{
        display:flex;
        align-items:center;
        gap:8px;
        img{
            cursor:pointer;
        }
    }
    span:last-of-type{
        font-size:14px;
    }
`

const ListItemGreen = css`
    background: var(--color-fill-success)
`

const ListItemRed = css`
    background: var(--color-fill-error)
`

const ListItemOrange = css`
    background: var(--color-fill-warning)
`

const ListItemBlue = css`
    background: var(--color-fill-info)
`

const ListItemLabelGreen = css`
    color: var(--color-text-success, #008035);
`

const ListItemLabelRed = css`
    color: var(--color-text-error, #B80015);
`

const ContentFeedbackPos = styled.div`
    height:98px;
    padding:10px;
    border-radius: 8px;
    margin-top:36px;
    p{
        margin: 0
    }
    p:first-of-type{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display:flex;
        align-items:center;
        gap:10px;
    }
    p:last-of-type{
        color:#000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top:10px;
    }
`

const WordsListContainer = styled.div`
    border-radius: 8px;
    background: var(--color-fill-section-secondary, #FAFAFA);
    padding:16px;
    h3{
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 160%;
        margin:0;
    }
    p{
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top:8px;
    }
`

const WordsList = styled.ul`
    
`

const WordsListItem = styled.li`
    button{
        display:flex;
        align-items:center;
        gap:43px;
        width:100%;
        text-align:left;
        margin: 20px 0;
        span{
            width:40%;
            color: #000;
            font-size: 20px;
            font-weight: 400;
            line-height: 160%;
        }
    }
`