import { createSlice } from "@reduxjs/toolkit";

const webSocketSlice = createSlice({
    name: "webWocketState",
    initialState: "closed",
    reducers : {
        setWebSocketState : (state, action) => {
            state = action.payload
            return state
          }
    }
});
  
export const {setWebSocketState} = webSocketSlice.actions;

export default webSocketSlice.reducer;