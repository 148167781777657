import { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import {Page, AuthPage, PrimaryButton} from "../global_styles/global_styles"
import Header from './shared/Header';
import { useNavigate } from 'react-router-dom';
import { acceptTerms } from '../util/acceptTerms';

function Terms() {

    const navigate = useNavigate()

    const handleContinue = () => {
        acceptTerms()
        .then((response)=>response.json())
        .then(data=>{
            if(data.username){
                // console.log(data)
                localStorage.setItem('profile', JSON.stringify(data));
                navigate(process.env.PUBLIC_URL + "/dashboard")
            }
        })
    }

    return(
        <Page className={css(AuthPage)}>
            <Header/>
            <TermsH1>Terms and conditions</TermsH1>
            <p>To use PTE AI Practice, please review the terms and conditions</p>
            <Disclaimer>
                <h2>Information</h2>
                <p><b>Welcome to PTE Prep!</b></p>
                <p>By using this feature, your audio responses and scores will be used to check the validity of the programme. Please note that AI-generated scores are for practice purposes only, not official assessments and may not always be accurate.By continuing, you consent to the processing of your personal information in accordance with our <a href="https://www.pearsonpte.com/policy-center/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                <p><b>By using this AI application, you agree to the following terms:</b></p>
                <p>We will be storing your audio responses and performance scores for further research and development purposes. Your data will be used to improve the accuracy and performance of the AI system.
The AI-generated scores are intended for practice purposes only and may not always be accurate or reflective of actual performance. Please use the feedback provided by the AI as a guide rather than a definitive assessment.
Please do not share any personal information during your interactions with the AI, such as your name, address, or other identifying details. You are responsible for maintaining the confidentiality of your login credentials and should not share your login access with anyone else.
This AI application is part of a pilot program, and any information regarding the product, its features, or performance should be kept confidential. Please do not disclose details about this program to third parties.
Please be aware that your data, including audio responses and scores, may be stored in a country where you are not currently residing, and by using this application, you consent to such cross-border data storage and processing.
By continuing to use this application, you acknowledge that you have read and agree to these terms.</p>
            </Disclaimer>
            <button 
                className={cx(PrimaryButton)}
                onClick={handleContinue}
            >
                I understand
            </button>
        </Page>
    )
}

export default Terms

const TermsH1 = styled.h1`
     margin-top:22px;
`

const Disclaimer = styled.div`
    border-radius: 8px;
    border: 1px solid var(--Neutrals---color-grayscale-030);
    // height:500px;
    overflow:auto;
    padding:16px;
    height:calc(100% - 250px);
    margin-bottom:24px;
    p{
        font-size: var(--font-size-b2, 16px);
    }
    a{
      color: #15498B;
      text-decoration-line: underline;
    }
`