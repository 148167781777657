import { useEffect, useState } from 'react';
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {questions} from '../data/questions.js'
import {getTypeProperName} from "../util/getTypeProperName.js"
import { getTimeString } from '../util/getTimeString.js';
import {Page, PrimaryButton, SecondaryButton} from "../global_styles/global_styles"
import { Link } from 'react-router-dom'
import Header from './shared/Header.jsx';
import GoalScore from './GoalScore.jsx';

//images
import check from "../images/check-complete.svg"
import clock from "../images/clock.svg"
import microphone from "../images/microphone_grey.svg"
import info from "../images/info_grey.svg"
import mixpanel from "mixpanel-browser";

function Dashboard() {

    const navigate = useNavigate()

    const userProfile = useSelector(state => state.userProfile)
    const userAttempts = useSelector(state => state.userAttempts)

    const [attemptedQuestions, setAttemptedQuestions] = useState([])
    const [showGoalScore, setShowGoalScore] = useState(false)

    const questionsByGroup = questions.reduce((acc, item) => {
        if (!acc[item.type]) {
        acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
    }, {});

    const hideGoalScore = () => {
      setShowGoalScore(false)
    }

    const getLevelText = (level) => {
      switch (level) {
        case 'B1':
          return "Easy"
          break;
        case 'B2':
          return "Medium"
          break;
        case 'C1':
            return "Hard"
            break;
        default:
          return "Unknown"
      }
    }

    useEffect(()=>{
      if(userProfile && Object.keys(userProfile).length != 0 && !userProfile.goalScore.goalScore){
        setShowGoalScore(true)
      }
    },[userProfile])

    useEffect(()=>{
        if(Array.isArray(userAttempts) && userAttempts.length > 0){
            let tempAttemptedArray = []
            userAttempts.forEach(attempt=>{
                tempAttemptedArray.push(attempt.questionId)
            })
            setAttemptedQuestions(tempAttemptedArray)
        }
    },[userAttempts])
    
    return (
        <Page className={css(DashboardPage)} showModal={showGoalScore}>
            {showGoalScore ? <GoalScore hideGoalScore={hideGoalScore}/> : null}
            <Header/>
            <DashboardH1>
                Prepare for PTE Academic
                <DashboardInfo className="info" src={info}/>
            </DashboardH1>
            <DashboardSubtitle>
                <DashboardH2><img src={microphone}/> Speaking</DashboardH2>
                <DashboardH2>20 questions</DashboardH2>
            </DashboardSubtitle>
            <ul>
                {
                    Object.keys(questionsByGroup).map((type, i)=>{
                        return(
                            <QuestionGroup key={i} type={type}>
                                <h3>{getTypeProperName(type)} ({questionsByGroup[type].length} questions)</h3>
                                <ul>
                                {
                                    questionsByGroup[type].map((question, j) => {
                                        return (
                                            <Question key={j}>
                                                <div>
                                                  <QuestionRow>
                                                      <CardTitle>
                                                          {
                                                              attemptedQuestions.length > 0 && attemptedQuestions.includes(question.id) ?
                                                              <img src={check}/>
                                                              : null
                                                          }
                                                          <p>{question.topic}</p>
                                                      </CardTitle>
                                                      {
                                                          attemptedQuestions.length > 0 && attemptedQuestions.includes(question.id) ?
                                                              <Link
                                                                  className={LinkButton}
                                                                  to={`/question/${question.id}`}
                                                                  onClick={() => mixpanel.track('Try Again', { 'questionId': question.id })}
                                                              >
                                                                  Try again
                                                              </Link>
                                                          : 
                                                          null
                                                      }
                                                  </QuestionRow>
                                                  <QuestionRow>
                                                      <CardMeta>
                                                          <Time>
                                                              <img src={clock}/>
                                                              <p>{getTimeString(question.type)}</p>
                                                          </Time>
                                                          <p>Level: {getLevelText(question.level)}</p>
                                                      </CardMeta>
                                                      {
                                                          attemptedQuestions.length > 0 && attemptedQuestions.includes(question.id) ?
                                                          <Link 
                                                              className={cx(SecondaryButton, ViewScoreButton)}
                                                              to={`/question/${question.id}/score`}
                                                              onClick={() => mixpanel.track('View Score - Dashboard', { 'questionId': question.id })}
                                                          >
                                                              View score
                                                          </Link>
                                                          : 
                                                          null
                                                      }
                                                  </QuestionRow>
                                                </div>
                                                {
                                                    !attemptedQuestions.includes(question.id) ?
                                                    <Link 
                                                        className={cx(PrimaryButton, OpenButton)}
                                                        to={`/question/${question.id}`}
                                                    >
                                                        Start
                                                    </Link>
                                                    : null
                                                }
                                            </Question>
                                        )
                                    })
                                }
                            </ul>
                            </QuestionGroup>
                        )
                    })
                }
            </ul>
        </Page>
    );
  }
  
  export default Dashboard;

  const getBackground = (type) => {
    switch(type) {
        case 'read_aloud':
          return '#F5F9FF'
          break;
        case 'repeat_sentence':
          return '#F0ECFF'
          break;
        case 'describe_image':
          return '#FFF5FB'
          break;
        case 'retell_lecture':
          return '#F8FFF5'
          break;
        case 'short_question':
          return '#FFF7EC'
          break;
        default:
          return '#F5F9FF'
      }
  }

  const getTextColor = (type) => {
    switch(type) {
        case 'read_aloud':
          return '#004BB9'
          break;
        case 'repeat_sentence':
          return '#7A00B3'
          break;
        case 'describe_image':
          return '#A6005B'
          break;
        case 'retell_lecture':
          return '#00662B'
          break;
        case 'short_question':
          return '#7A4700'
          break;
        default:
          return '#004BB9'
      }
  }

  const DashboardPage = css`
   background: #FFF !important;
  `

  const DashboardH1 = styled.h1`
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display:flex;
    align-items:center;
    gap:8px;
    margin-top:22px;
    margin-bottom:24px;
  `

  const DashboardInfo = styled.img`
    cursor:pointer;
  `

  const DashboardSubtitle = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    margin-bottom:16px;
  `

  const DashboardH2 = styled.h2`
    color: var(--Neutrals---color-grayscale-080);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    display:flex;
    align-items:center;
    img{
        margin-right:4px;
    }
  `

  const QuestionGroup = styled.div`
    padding: 8px;
    background: ${props => getBackground(props.type)};
    margin-bottom:16px;
    h3{
        color: ${props => getTextColor(props.type)};
        margin: 0; 
        margin-bottom:8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }  
  `

  const Question = styled.li`
    border-radius: 4px;
    border: 1px solid var(--color-stroke-container-secondary);
    background: #FFF;
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-sm, 2px) var(--elevation-blur-xs, 4px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:8px;
    margin-bottom:8px;
    min-height:72px;
    &>div{
      flex: 1;
    }
  `

  const QuestionRow = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    &:first-of-type{
      margin-bottom:8px;
    }
    
  `

  const CardTitle = styled.div`
        display:flex;
        gap:16px;  
        p{
            margin:0;
            color: var(--color-text-primary);
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }
  `

  const CardMeta = styled.div`
    display:flex;
    align-items:center;
    gap:12px;
    p{
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--color-text-secondary);
    }
  `

  const Time = styled.div`
    display:flex;
    align-items:center;
    gap:5px;
    border: 1px solid var(--color-stroke-container-secondary);
    border-radius: 100px;
    padding: 2px 4px;
    img{
        width:14px;
        height:14px;
    }
  `

  const OpenButton = css`
    width:66px;
    text-decoration:none;
    display:flex;
    align-items:center;
    justify-content:center;
  `

  const LinkButton = css`
    color: var(--color-text-interactive-brand-default);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 107px;
    display:flex;
    align-items:center;
    justify-content:center;
  `

  const ViewScoreButton = css`
    height:40px;
    width:107px;
  `

  const QuestionActions = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
  `


  