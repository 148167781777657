import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import PageWrapper from './PageWrapper'
import ProtectedRoute from './ProtectedRoute';
import AuthRoute from './AuthRoute';
import Signup from "../components/Signup"
import Signin from "../components/Signin"
import Terms from '../components/Terms';
import Dashboard from '../components/Dashboard';
import Question from '../components/Question';
import TestAPI from "../components/TestAPI"

// Create the router configuration
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Navigate to="/signin"/>
    },
    {
      element: <AuthRoute/>,
      children: [
        {
          path: '/signin',
          element: <PageWrapper><Signin/></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/signup',
          element: <PageWrapper><Signup /></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/terms',
          element: <PageWrapper><Terms/></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/dashboard',
          element: <PageWrapper><Dashboard/></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/question/:questionId',
          element: <PageWrapper><Question/></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/question/:questionId/:score',
          element: <PageWrapper><Question/></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/question/:questionId/:score/:content',
          element: <PageWrapper><Question/></PageWrapper>
        },
      ]
    },
    {
      element: <ProtectedRoute/>,
      children: [
        {
          path: '/api',
          element: <PageWrapper><TestAPI/></PageWrapper>
        },
      ]
    },
    {
      path: '*',
      element: <p>404 Error - Nothing here...</p>
    }
  ]
);

export default router;