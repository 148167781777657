import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
    name: "processedFeedback",
    initialState: [],
    reducers : {
        addNewFeedback: (state, action) => {
            let newState = [...state]
            if(newState.every(entry=>entry.questionId != action.payload.questionId)){
                newState.push(action.payload)
            }
            return newState
        },
        updateFeedback: (state, action) => {
            let newState = [...state]
            let feedbackMatch = newState.filter(feedback => feedback.questionId === Number(action.payload.questionId))
            newState[newState.indexOf(feedbackMatch[0])] = action.payload
            return newState
        }
    }
});
  
export const { addNewFeedback, updateFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;